import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DynamicObject } from '../entities/common.entities';

@Injectable()
export class DataStoreService {
    private currentStoreSubject = new BehaviorSubject<DynamicObject>({} as DynamicObject);
    public currentStore = this.currentStoreSubject.asObservable();
    private exclusions = ['PERMISSION'];
    constructor() {}

    setData(key: string, value: any, forceSubscribe: boolean = true, target: string = '') {
        const currentStore = this.getCurrentStore();
        currentStore[key] = value;
        currentStore['ENFORCE_SUBSCRIPTION'] = forceSubscribe;
        currentStore['SUBSCRIPTION_TARGET'] = target;
        this.currentStoreSubject.next(currentStore);
    }

    setObject(value: any, forceSubscribe: boolean = true, target: string = '') {
        value['ENFORCE_SUBSCRIPTION'] = forceSubscribe;
        value['SUBSCRIPTION_TARGET'] = target;
        this.currentStoreSubject.next(value);
    }

    getData(key: string): any {
        const currentStore = this.getCurrentStore();
        return currentStore[key];
    }

    clearStore() {
        const currentStore = this.getCurrentStore();
        Object.keys(currentStore).forEach((key) => {
            if (this.exclusions.indexOf(key) === -1) {
                delete currentStore[key];
            }
        });
        this.currentStoreSubject.next(currentStore);
    }

    getCurrentStore(): DynamicObject {
        return this.currentStoreSubject.value;
    }
}
