import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/internal/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  overrideUrl = true;
  errorData: HttpErrorResponse;
  baseUrl = '';
  public headers = new HttpHeaders()
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('role', 'role=CP_PUBLIC');

  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public outsideShowSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiHost;
  }

  handleError(error: HttpErrorResponse) {
    this.hideSpinner();
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error?.error?.message ? error?.error?.message : error.message}`;
    }
    // window.alert(errorMessage);

    return throwError(error);
  }

  public hideSpinner() {
    this.showSpinner.next(false);
  }

  public get(url: string, params = {}): Observable<any> {
    if(!url.includes('notification')) {
      this.showSpinner.next(true);
    }

    const response = this.request('GET', url, {}, params);
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public post(url: string, body: any = {}, params = {}): Observable<any> {
    this.showSpinner.next(true);
    const response = this.request('POST', url, body, params);
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public download(url: string, body: any = {}, params = {}, responseType: string): Observable<any> {
    return this.request('POST', url, body, params, responseType);
  }

  public put(url: string, body: any = {}, params = {}): Observable<any> {
    this.showSpinner.next(true);
    const response = this.request('PUT', url, body, params);
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public patch(url: string, body: any = {}, params = {}): Observable<any> {
    this.showSpinner.next(true);
    const response = this.request('PATCH', url, body, params);
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public delete(url: string, body: any = {}, params = {}): Observable<any> {
    this.showSpinner.next(true);
    const response = this.request('DELETE', url, body, params, 'string');
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public request(method: string, url, body: any = {}, params = {}, responseType = null) {
    if (!this.overrideUrl) {
      url = `${this.baseUrl}/${url}`;
    }

    const paramsBuild: any = this.buildParams(params);
    const options: any = {
      body,
      headers: this.headers,
      params: paramsBuild,
      responseType: 'json'
    };
    if (responseType) {
      options.responseType = responseType;
    }
    return this.http.request(method, url, options);
  }

  public externalAPI(method: string, url, body: any = {}, params = {}, responseType = null, headers = null): Observable<any> {
    this.showSpinner.next(true);
    const response = this.externalRequest(method, url, body, params, responseType, headers);
    return response.pipe(
      tap(_ => this.hideSpinner()),
      catchError(this.handleError.bind(this)),
    );
  }

  public externalRequest(method: string, url, body: any = {}, params = {}, responseType = null, headers = null) {
    const paramsBuild: any = this.buildParams(params);
    const options: any = {
      body,
      headers: headers ? headers : this.headers,
      params: paramsBuild,
      responseType: 'json'
    };
    if (responseType) {
      options.responseType = responseType;
    }
    return this.http.request(method, url, options);
  }

  public buildParams(paramsObj: any): HttpParams {
    let params = new HttpParams();
    Object.keys(paramsObj).forEach((key) => {
      params = params.set(key, paramsObj[key]);
    });
    return params;
  }


}
