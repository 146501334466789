<div class="ui-theme-settings">
     <!-- <button type="button" placement="left" ngbTooltip="Ask for Help"
          class="btn-open-options btn btn-warning" (click)="toggleOptionsDrawer()" *ngIf="auth.isAdmin()">
          <fa-icon [icon]="faCog" size="3x" [spin]="true"></fa-icon>
     </button> -->
     <div class="theme-settings__inner">
          <div class="scrollbar-container">
               <perfect-scrollbar [autoPropagation]="true">
                    <div class="theme-settings__options-wrapper">
                         <h3 class="themeoptions-heading">
                              Layout Options
                         </h3>
                         <div class="p-3">
                              <ul class="list-group">
                                   <li class="list-group-item">
                                        <div class="widget-content p-0">
                                             <div class="widget-content-wrapper">
                                                  <div class="widget-content-left mr-3">
                                                       <mat-checkbox (change)="toggleFixedFooter()"></mat-checkbox>
                                                  </div>
                                                  <div class="widget-content-left">
                                                       <div class="widget-heading">Fixed Footer
                                                       </div>
                                                       <div class="widget-subheading">Makes the app footer bottom fixed,
                                                            always visible!
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </li>
                              </ul>
                         </div>
                         <h3 class="themeoptions-heading">
                              <div>
                                   Header Options
                              </div>
                              <button type="button" (click)="updateConfig({headerTheme: 'bg-night-sky text-lighter' })"
                                   class="btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm">
                                   Restore Default
                              </button>
                         </h3>
                         <div class="p-3">
                              <ul class="list-group">
                                   <li class="list-group-item">
                                        <h5 class="pb-2">
                                             Choose Color Scheme
                                        </h5>
                                        <div class="theme-settings-swatches">
                                             <div class="swatch-holder bg-dmi-blue"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-blue text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-primary"
                                                  (click)="updateConfig({headerTheme: 'bg-primary text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-secondary"
                                                  (click)="updateConfig({headerTheme: 'bg-secondary text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-success"
                                                  (click)="updateConfig({headerTheme: 'bg-success text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-white"
                                                  (click)="updateConfig({headerTheme: 'bg-white text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-info"
                                                  (click)="updateConfig({headerTheme: 'bg-info text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-warning"
                                                  (click)="updateConfig({headerTheme: 'bg-warning text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-danger"
                                                  (click)="updateConfig({headerTheme: 'bg-danger text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-light"
                                                  (click)="updateConfig({headerTheme: 'bg-light text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-dark"
                                                  (click)="updateConfig({headerTheme: 'bg-dark text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-focus"
                                                  (click)="updateConfig({headerTheme: 'bg-focus text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-alternate"
                                                  (click)="updateConfig({headerTheme: 'bg-alternate text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu"
                                                  (click)="updateConfig({headerTheme: 'bg-wbu text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu-brown"
                                                  (click)="updateConfig({headerTheme: 'bg-wbu-brown text-lighter' })">
                                             </div>

                                             <div class="divider">
                                             </div>
                                             <div class="swatch-holder bg-vicious-stance"
                                                  (click)="updateConfig({headerTheme: 'bg-vicious-stance text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-midnight-bloom"
                                                  (click)="updateConfig({headerTheme: 'bg-midnight-bloom text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-night-sky"
                                                  (click)="updateConfig({headerTheme: 'bg-night-sky text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-slick-carbon"
                                                  (click)="updateConfig({headerTheme: 'bg-slick-carbon text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-asteroid"
                                                  (click)="updateConfig({headerTheme: 'bg-asteroid text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-royal"
                                                  (click)="updateConfig({headerTheme: 'bg-royal text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-warm-flame"
                                                  (click)="updateConfig({headerTheme: 'bg-warm-flame text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-night-fade"
                                                  (click)="updateConfig({headerTheme: 'bg-night-fade text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-sunny-morning"
                                                  (click)="updateConfig({headerTheme: 'bg-sunny-morning text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-tempting-azure"
                                                  (click)="updateConfig({headerTheme: 'bg-tempting-azure text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-amy-crisp"
                                                  (click)="updateConfig({headerTheme: 'bg-amy-crisp text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-heavy-rain"
                                                  (click)="updateConfig({headerTheme: 'bg-heavy-rain text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-mean-fruit"
                                                  (click)="updateConfig({headerTheme: 'bg-mean-fruit text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-malibu-beach"
                                                  (click)="updateConfig({headerTheme: 'bg-malibu-beach text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-deep-blue"
                                                  (click)="updateConfig({headerTheme: 'bg-deep-blue text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-ripe-malin"
                                                  (click)="updateConfig({headerTheme: 'bg-ripe-malin text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-arielle-smile"
                                                  (click)="updateConfig({headerTheme: 'bg-arielle-smile text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-plum-plate"
                                                  (click)="updateConfig({headerTheme: 'bg-plum-plate text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-fisher"
                                                  (click)="updateConfig({headerTheme: 'bg-happy-fisher text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-itmeo"
                                                  (click)="updateConfig({headerTheme: 'bg-happy-itmeo text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-mixed-hopes"
                                                  (click)="updateConfig({headerTheme: 'bg-mixed-hopes text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-strong-bliss"
                                                  (click)="updateConfig({headerTheme: 'bg-strong-bliss text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-grow-early"
                                                  (click)="updateConfig({headerTheme: 'bg-grow-early text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-love-kiss"
                                                  (click)="updateConfig({headerTheme: 'bg-love-kiss text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-premium-dark"
                                                  (click)="updateConfig({headerTheme: 'bg-premium-dark text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-green"
                                                  (click)="updateConfig({headerTheme: 'bg-happy-green text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-red-chanje"
                                                  (click)="updateConfig({headerTheme: 'bg-red-chanje text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-new"
                                                  (click)="updateConfig({headerTheme: 'bg-blue-new text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-orange-new"
                                                  (click)="updateConfig({headerTheme: 'bg-orange-new text-lighter' })">
                                             </div>
                                             <!-- <div class="swatch-holder bg-green-new"
                                                  (click)="updateConfig({headerTheme: 'bg-green-new text-lighter' })">
                                             </div> -->
                                             <!-- <div class="swatch-holder bg-red-black-new"
                                                  (click)="updateConfig({headerTheme: 'bg-red-black-new text-lighter' })">
                                             </div>     -->
                                             <div class="swatch-holder bg-Cadmium-new"
                                                  (click)="updateConfig({headerTheme: 'bg-Cadmium-new text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-Cerise-new"
                                                  (click)="updateConfig({headerTheme: 'bg-Cerise-new text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-Rajah-new"
                                                  (click)="updateConfig({headerTheme: 'bg-Rajah-new text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu-new"
                                                  (click)="updateConfig({headerTheme: 'bg-wbu-new text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu-reverse"
                                                  (click)="updateConfig({headerTheme: 'bg-wbu-reverse text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-green"
                                                  (click)="updateConfig({headerTheme: 'bg-blue-green text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-drak-gray"
                                                  (click)="updateConfig({headerTheme: 'bg-drak-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-gray"
                                                  (click)="updateConfig({headerTheme: 'bg-blue-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-light-gray"
                                                  (click)="updateConfig({headerTheme: 'bg-light-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-yellow"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-yellow text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-teal"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-teal text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-purple"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-purple text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-seafoam"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-seafoam text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-cyan"
                                                  (click)="updateConfig({headerTheme: 'bg-dmi-cyan text-lighter' })">
                                             </div>
                                             
                                        </div>
                                   </li>
                              </ul>
                         </div>
                         <h3 class="themeoptions-heading">
                              <div>Sidebar Options</div>
                              <button type="button"
                                   (click)="updateConfig({sidebarTheme: 'bg-vicious-stance text-lighter' })"
                                   class="btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm switch-sidebar-cs-class">
                                   Restore Default
                              </button>
                         </h3>
                         <div class="p-3">
                              <ul class="list-group">
                                   <li class="list-group-item">
                                        <h5 class="pb-2">Choose Color Scheme
                                        </h5>
                                        <div class="theme-settings-swatches">
                                             <div class="swatch-holder bg-dmi-dark-blue"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-dark-blue text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-primary"
                                                  (click)="updateConfig({sidebarTheme: 'bg-primary text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-secondary"
                                                  (click)="updateConfig({sidebarTheme: 'bg-secondary text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-success"
                                                  (click)="updateConfig({sidebarTheme: 'bg-success text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-info"
                                                  (click)="updateConfig({sidebarTheme: 'bg-info text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-white"
                                                  (click)="updateConfig({sidebarTheme: 'bg-white text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-warning"
                                                  (click)="updateConfig({sidebarTheme: 'bg-warning text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-danger"
                                                  (click)="updateConfig({sidebarTheme: 'bg-danger text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-light"
                                                  (click)="updateConfig({sidebarTheme: 'bg-light text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-dark"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dark text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-focus"
                                                  (click)="updateConfig({sidebarTheme: 'bg-focus text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-alternate"
                                                  (click)="updateConfig({sidebarTheme: 'bg-alternate text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu"
                                                  (click)="updateConfig({sidebarTheme: 'bg-wbu text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu-brown"
                                                  (click)="updateConfig({sidebarTheme: 'bg-wbu-brown text-lighter' })">
                                             </div>
                                             
                                             <div class="divider">
                                             </div>
                                             <div class="swatch-holder bg-vicious-stance"
                                                  (click)="updateConfig({sidebarTheme: 'bg-vicious-stance text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-midnight-bloom"
                                                  (click)="updateConfig({sidebarTheme: 'bg-midnight-bloom text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-night-sky"
                                                  (click)="updateConfig({sidebarTheme: 'bg-night-sky text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-slick-carbon"
                                                  (click)="updateConfig({sidebarTheme: 'bg-slick-carbon text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-asteroid"
                                                  (click)="updateConfig({sidebarTheme: 'bg-asteroid text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-royal"
                                                  (click)="updateConfig({sidebarTheme: 'bg-royal text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-warm-flame"
                                                  (click)="updateConfig({sidebarTheme: 'bg-warm-flame text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-night-fade"
                                                  (click)="updateConfig({sidebarTheme: 'bg-night-fade text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-sunny-morning"
                                                  (click)="updateConfig({sidebarTheme: 'bg-sunny-morning text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-tempting-azure"
                                                  (click)="updateConfig({sidebarTheme: 'bg-tempting-azure text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-amy-crisp"
                                                  (click)="updateConfig({sidebarTheme: 'bg-amy-crisp text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-heavy-rain"
                                                  (click)="updateConfig({sidebarTheme: 'bg-heavy-rain text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-mean-fruit"
                                                  (click)="updateConfig({sidebarTheme: 'bg-mean-fruit text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-malibu-beach"
                                                  (click)="updateConfig({sidebarTheme: 'bg-malibu-beach text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-deep-blue"
                                                  (click)="updateConfig({sidebarTheme: 'bg-deep-blue text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-ripe-malin"
                                                  (click)="updateConfig({sidebarTheme: 'bg-ripe-malin text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-arielle-smile"
                                                  (click)="updateConfig({sidebarTheme: 'bg-arielle-smile text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-plum-plate"
                                                  (click)="updateConfig({sidebarTheme: 'bg-plum-plate text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-fisher"
                                                  (click)="updateConfig({sidebarTheme: 'bg-happy-fisher text-darker' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-itmeo"
                                                  (click)="updateConfig({sidebarTheme: 'bg-happy-itmeo text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-mixed-hopes"
                                                  (click)="updateConfig({sidebarTheme: 'bg-mixed-hopes text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-strong-bliss"
                                                  (click)="updateConfig({sidebarTheme: 'bg-strong-bliss text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-grow-early"
                                                  (click)="updateConfig({sidebarTheme: 'bg-grow-early text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-love-kiss"
                                                  (click)="updateConfig({sidebarTheme: 'bg-love-kiss text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-premium-dark"
                                                  (click)="updateConfig({sidebarTheme: 'bg-premium-dark text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-happy-green"
                                                  (click)="updateConfig({sidebarTheme: 'bg-happy-green text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-red-chanje-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-red-chanje-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-new-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-blue-new-side text-lighter' })">
                                             </div>
                                             <!-- <div class="swatch-holder bg-orange-new-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-orange-new-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-green-new-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-green-new-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-red-black-new-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-red-black-new-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-Cadmium-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-Cadmium-side text-lighter' })">
                                             </div> -->
                                             <div class="swatch-holder bg-Cerise-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-Cerise-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-Rajah-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-Rajah-side text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-wbu-new-side"
                                                  (click)="updateConfig({sidebarTheme: 'bg-wbu-new-side text-lighter' })">
                                             </div>
                                             <!-- <div class="swatch-holder bg-wbu-reverse"
                                                  (click)="updateConfig({sidebarTheme: 'bg-wbu-reverse text-lighter' })">
                                             </div> -->
                                             <div class="swatch-holder bg-blue-green-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-blue-green text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-drak-gray"
                                                  (click)="updateConfig({sidebarTheme: 'bg-drak-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-gray"
                                                  (click)="updateConfig({sidebarTheme: 'bg-blue-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-light-gray"
                                                  (click)="updateConfig({sidebarTheme: 'bg-light-gray text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-gray-blue"
                                                  (click)="updateConfig({sidebarTheme: 'bg-gray-blue text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-blue-green-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-blue-green-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-yellow-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-yellow-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-teal-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-teal-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-purple-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-purple-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-seafoam-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-seafoam-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-dmi-cyan-sidebar"
                                                  (click)="updateConfig({sidebarTheme: 'bg-dmi-cyan-sidebar text-lighter' })">
                                             </div>
                                             <div class="swatch-holder bg-glass-1"
                                                  (click)="updateConfig({sidebarTheme: 'bg-glass-1 text-lighter' })">
                                             </div>
                                              <div class="swatch-holder bg-glass-2"
                                                  (click)="updateConfig({sidebarTheme: 'bg-glass-2 text-lighter' })">
                                             </div>
                                              <div class="swatch-holder bg-glass-3"
                                                  (click)="updateConfig({sidebarTheme: 'bg-glass-3 text-lighter' })">
                                             </div>
                                              <div class="swatch-holder bg-glass-4"
                                                  (click)="updateConfig({sidebarTheme: 'bg-glass-4 text-lighter' })">
                                             </div>
                                              <div class="swatch-holder gradient-king-yna"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-king-yna text-lighter' })">
                                             </div>
                                              <div class="swatch-holder gradient-mint"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-mint text-lighter' })">
                                             </div>
                                              <div class="swatch-holder gradient-ibiza-sunset"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-ibiza-sunset text-lighter' })">
                                             </div>
                                             <div class="swatch-holder gradient-flickr"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-flickr text-lighter' })">
                                             </div>
                                             <div class="swatch-holder gradient-purple-bliss"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-purple-bliss text-lighter' })">
                                             </div>
                                             <div class="swatch-holder gradient-man-of-steel"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-man-of-steel text-lighter' })">
                                             </div>
                                              <div class="swatch-holder gradient-purple-love"
                                                  (click)="updateConfig({sidebarTheme: 'gradient-purple-love text-lighter' })">
                                             </div>
                                        </div>
                                   </li>
                              </ul>
                         </div>
                         <div class="p-3">
                              <ul class="list-group">
                                   <li class="list-group-item">
                                        <h5 class="pb-2">Choose Font Family and Size</h5>
                                        <select placeholder="Font Family"  (change)="onFontChange($event)">
                                             <option value="poppins">Poppins</option>
                                             <option value="spaceGrotesk">Space Greotesk</option>
                                             <option value="helvetica-neue">Helvetica Neue</option>
                                             <option value="roboto">Roboto</option>
                                             <option value="montserrat">Montserrat</option>
                                             <option value="archivo">Archivo</option>
                                             <option value="inter">Inter</option>
                                             <option value="lato">Lato</option>
                                             <option value="raleway">Raleway</option>
                                             <option value="spectral">Spectral</option>
                                        </select>
                                        <select placeholder="Font Size"  (change)="onFontSizeChange($event)" class="ml-2">
                                             <option value="9px">9</option>
                                             <option value="10px">10</option>
                                             <option value="11px">11</option>
                                             <option value="12px">12</option>
                                             <option value="13px">13</option>
                                             <option value="14px">14</option>
                                             <option value="15px">15</option>
                                             <option value="16px">16</option>
                                             <option value="17px">17</option>
                                             <option value="18px">18</option>
                                             <option value="19px">19</option>
                                             <option value="20px">20</option>
                                             <option value="21px">21</option>
                                             <option value="22px">22</option>
                                        </select>
                                   </li>
                              </ul>
                         </div>
                         <div class="p-3">
                              <ul class="list-group">
                                   <li class="list-group-item" [formGroup]="deptForm">

                                        Organization Name
                                        <select placeholder="Organization Name" required formControlName="orgid"
                                             (change)="onOrgChange()">
                                             <option *ngFor="let org of orgList" value="{{org.id}}">
                                                  {{org.organizationname}}</option>
                                        </select>

                                        <div class="swatch-holder {{themedata.headerTheme}}">
                                        </div>
                                        <div class="swatch-holder {{themedata.sidebarTheme}}">
                                        </div>
                                   </li>
                                   <li class="list-group-item">
                                        <button type="button" class="btn btn-primary" (click)="saveTheme()">Save
                                             changes</button>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </perfect-scrollbar>
          </div>
     </div>

     <!-- Chat Option -->
     <!-- <button type="button" placement="left" ngbTooltip="Ask for Help"
          class="btn-open-options btn btn-warning" (click)="toggleChat()" *ngIf="!auth.isAdmin()"> 
          <fa-icon [icon]="faComment" size="3x" [spin]="false"></fa-icon>
     </button> -->
</div>

<div class="chat-bar" *ngIf="showChatBot">
  <div class="chat-msg">
    <div class="topCLoseSet clearfix">
      <div class="chatCloseIcon f-right" (click)="toggleChat()">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <div class="chatBotStart clearfix">
      <div class="chat-header clearfix">
        <div class="f-left leftChatLogo clearfix">
          <img src="../../../assets/images/logo.png" alt="logo" />
        </div>
        <div class="rightCharHeadingText f-left">
          <p>Ask Us</p>
        </div>
      </div>
      <div class="chatBodySet clearfix">
        <div class="innerChatBoxarea">
          <!-- <iframe src="https://chatbot.hellotars.com/conv/4J5u6X/"
            style="width: 100%; height: 100%; padding: 0; margin: 0; border: 0; outline: none;"
            *ngIf="appUser.role.name !== 'field'"></iframe> -->
          <iframe src="https://chatbot.hellotars.com/conv/4J5u6X/?_startgid=25"
            style="width: 100%; height: 100%; padding: 0; margin: 0; border: 0; outline: none;"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
