import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faCog, faComment } from '@fortawesome/free-solid-svg-icons';
import { ConfigActions } from '../store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { AuthService } from 'src/app/@core/services/auth.service';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { CommonUrlConfig } from 'src/app/@core/common/URLS/common-url.config';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/@core/services/alert.services';

@Component({
  selector: 'app-options-drawer',
  templateUrl: './options-drawer.component.html',
	styleUrls: ['./option-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OptionsDrawerComponent implements OnInit{
  deptForm: FormGroup;
  faCog = faCog;
  faComment = faComment;
  orgList : any;
  themeList: any;
  themedata = {'headerTheme':'','sidebarTheme':''};
  showChatBot: Boolean;
  toggleOptionsDrawer() {
    this.globals.toggleThemeOptions = !this.globals.toggleThemeOptions;
  }

  toggleFixedFooter() {
    this.globals.toggleFixedFooter = !this.globals.toggleFixedFooter;
  }

  constructor(public globals: ThemeOptions,
    public configActions: ConfigActions,public auth: AuthService, private commonHttpService: CommonHttpService,private formBuilder: FormBuilder, private alert: AlertService) {
      this.loadDeptForm();
      this.load(this.auth.getOrgID());
      this.getOrgList();

   }

   ngOnInit(): void {
    this.load(this.auth.getOrgID());
   }

   updateConfig(config): void {
    this.configActions.updateConfig(config);
    if(config['headerTheme'])
    this.themedata['headerTheme'] = config['headerTheme'];
    if(config['sidebarTheme'])
    this.themedata['sidebarTheme'] = config['sidebarTheme'];
    this.deptForm.patchValue({"theme": this.themedata});
  }

  loadDeptForm() {
    this.deptForm = this.formBuilder.group({
      id: new FormControl(null),
      orgid:new FormControl(null),
      theme: new FormControl(null),
    });
  }

   load(id) {

      this.commonHttpService.getArrayList({}, CommonUrlConfig.EndPoint.rbac.usertheme.list.replace("{orgId}",id), 'rbac').
        subscribe(data => {
          if (data && data.length) {
            this.themeList = data;
            this.deptForm.patchValue(this.themeList[0]);
            var theme = JSON.parse(this.themeList[0]['theme']);
            this.updateConfig({headerTheme:theme['headerTheme']});
            this.updateConfig({sidebarTheme:theme['sidebarTheme']});
          } else {
            this.alert.info('Theme is not avilable for selected Organization');
            this.themeList = [];
            this.themedata = {'headerTheme':'','sidebarTheme':''};
          }
        });
    }

    getOrgList() {
      this.commonHttpService.getArrayList({}, CommonUrlConfig.EndPoint.rbac.organization.list + 'all', 'rbac').
        subscribe(data => {
          if (data && data.length) {
            this.orgList = data;
          } else {
            this.orgList = [];
          }
        });
    }

    saveTheme() {
      const bUnit = this.deptForm.getRawValue();
      this.commonHttpService.create(bUnit, CommonUrlConfig.EndPoint.rbac.usertheme.save, 'rbac').subscribe(result => {
          this.alert.success('Theme saved Successfully');
      }, error => {
        console.log(error);
      });
  }

  onOrgChange(){
    this.load(this.deptForm.get("orgid")?this.deptForm.get("orgid").value:'');
  }

  onFontChange(event: any) {
    const fontFamily = event.target.value;
    // alert(fontFamily);
    document.documentElement.style.setProperty('--font', fontFamily);
  }

  onFontSizeChange(event: any) {
    const fontSize = event.target.value;
    // alert(fontSize);
    document.documentElement.style.setProperty('--font-size', fontSize);
  }

  // Chat BOT

  toggleChat() {
    this.showChatBot = !this.showChatBot;
  }

}
