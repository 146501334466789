import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styles: []
})
export class AutologinComponent implements OnInit {

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    const userCredentical = {
        user: 'mkradhakrishnan@dminc.com',
        password: 'password',
        aggree: true
    } 
    this.auth.login(userCredentical);
  }
}
