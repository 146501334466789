<link *ngIf="customTheme" rel="stylesheet" [href]='customTheme | sanitize'>

<div id="ng-chat" *ngIf="!isDisabled && isBootstrapped && !unsupportedViewport" [ngClass]="theme">
    <ng-chat-friends-list
        [localization]="localization"
        [shouldDisplay]="!hideFriendsList"
        [userId]="userId"
        [isCollapsed]="isCollapsed"
        [searchEnabled]="searchEnabled"
        [participants]="participants"
        [participantsResponse]="participantsResponse"
        [participantsInteractedWith]="participantsInteractedWith"
        [windows]="windows"
        [currentActiveOption]="currentActiveOption"
        (onParticipantClicked)="onParticipantClickedFromFriendsList($event)"
        (onOptionPromptCanceled)="onOptionPromptCanceled()"
        (onOptionPromptConfirmed)="onOptionPromptConfirmed($event)"
    >
    </ng-chat-friends-list>

    <div *ngFor="let window of windows; let i = index" [ngClass]="{'ng-chat-window': true, 'primary-outline-color': true, 'ng-chat-window-collapsed': window.isCollapsed}" [ngStyle]="{'right': (!hideFriendsList ? friendsListWidth : 0) + 20 + windowSizeFactor * i + 'px'}">
        <ng-chat-window
            #chatWindow
            [fileUploadAdapter]="fileUploadAdapter"
            [localization]="localization"
            [userId]="userId"
            [window]="window"
            [showOptions]="groupAdapter"
            [emojisEnabled]="emojisEnabled"
            [linkfyEnabled]="linkfyEnabled"
            [showMessageDate]="showMessageDate"
            [messageDatePipeFormat]="messageDatePipeFormat"
            [hasPagedHistory]="hasPagedHistory"
            (onMessagesSeen)="onWindowMessagesSeen($event)"
            (onMessageSent)="onWindowMessageSent($event)"
            (onTabTriggered)="onWindowTabTriggered($event)"
            (onChatWindowClosed)="onWindowChatClosed($event)"
            (onOptionTriggered)="onWindowOptionTriggered($event)"
            (onLoadHistoryTriggered)="fetchMessageHistory($event)"
        >
        </ng-chat-window>
    </div>
</div>
