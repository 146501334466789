<div class="fsize "> 
    <div class="success-loader" style="display:inline-block;vertical-align: middle;">
        {{message}}
      </div>
    <div style="display:inline-block;vertical-align: middle;" >
        <img src="{{imageSrc}}" alt="loading..." height="35px" width="35px">
      </div>

  

</div>

