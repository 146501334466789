import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ssnMask' })
export class MaskPipe implements PipeTransform {
	transform(value: string, showSsnMask: boolean): string {
		if (showSsnMask === true) {
			if (String(value).startsWith('*')) {
				return '';
			}
			if (String(value).match('^[0-9]{9}$')) {				
				return '***-**-' + String(value).substr(String(value).length - 4);
			} else { return ''; }
		} else {
            const cleaned = ('' + value).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            } else {
                if (String(value).startsWith('*')) {
					return '';
				}
				if (String(value).match('^[0-9]{9}$')) {
					return (String(value).substring(0, 3) + '-' + String(value).substring(3, 5) + '-' + String(value).substring(5, 9));
					
				} else { return ''; }
            }
			
		}
		return value;
	}
}
