<div class="app-drawer-wrapper" [ngClass]="{'drawer-open' : toggleDrawer}"  >
  <div class="drawer-nav-btn">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div>
  <div class="drawer-content-wrapper">
    <section class="scrollbar-container">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="todo-notif-wrp" *ngIf="user?.role?.rolekey === 'CW'">
          <h3 class="drawer-heading">To Do List</h3>
          <div class="drawer-section p-0">
            <div class="todo-box">
              <ul class="todo-list-wrapper list-group list-group-flush">
                <li class="list-group-item">
                  <div class="todo-indicator bg-warning"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox1266"
                            class="custom-control-input"><label for="exampleCustomCheckbox1266"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Complete Investigation Summary for Case# <a [routerLink]="['/pages/dynamic-routing/67c45f06-3b69-4abe-a374-445e0eefb2fc/dynamicpage/tab/case/AR/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">AR21041500024</a></div>
                        <!-- <div class="widget-subheading">This task has show on hover actions!</div> -->
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-focus"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox1666"
                            class="custom-control-input"><label for="exampleCustomCheckbox1666"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Notify the caregiver(s) of service recommendations for Case# <a [routerLink]="['/pages/dynamic-routing/f9cced19-fc57-40fc-83a1-5ae9e914a2b9/dynamicpage/tab/case/service/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">SC21041500022</a></div>
                        <!-- <div class="widget-subheading">This task has show on hover actions!</div> -->
                      </div>
                      <!-- <div class="widget-content-right widget-content-actions">
                        <div class="d-inline-block">
                          <button type="button" class="btn btn-focus btn-transition">
                            <fa-icon [icon]="faEllipsisH"></fa-icon>
                          </button>
                        </div>
                      </div> -->
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-primary"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox4777"
                            class="custom-control-input"><label for="exampleCustomCheckbox4777"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Document intial contact with family members for Case# <a [routerLink]="['/pages/dynamic-routing/4995b535-64a4-47d3-99d1-373f9d49f6f0/dynamicpage/tab/case/service/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">SC21041500025</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-info"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2444"
                            class="custom-control-input"><label for="exampleCustomCheckbox2444"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Update Service Log for Case# <a [routerLink]="['/pages/dynamic-routing/f9cced19-fc57-40fc-83a1-5ae9e914a2b9/dynamicpage/tab/case/service/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">SC21041500022</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-sm btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-sm btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-info"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2444"
                            class="custom-control-input"><label for="exampleCustomCheckbox2444"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Create Safety Plan for Case# <a [routerLink]="['/pages/dynamic-routing/4995b535-64a4-47d3-99d1-373f9d49f6f0/dynamicpage/tab/case/service/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">SC21041500025</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-sm btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-sm btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <h3 class="drawer-heading">Notifications</h3>
          <div class="drawer-section">
            <div class="notifications-box">
              <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--one-column">
                <div class="vertical-timeline-item dot-danger vertical-timeline-element">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">Child Placement Approved by Leon, Roy for Case# <a [routerLink]="['/pages/dynamic-routing/8a55c68c-8b52-4fc4-8377-818b7e3d90be/dynamicpage/tab/case/IR/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">IR21041500026</a> </h4><span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
                <div class="vertical-timeline-item dot-success vertical-timeline-element">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">Permanency Plan Approved by Leon, Roy for Case# <a [routerLink]="['/pages/dynamic-routing/f9cced19-fc57-40fc-83a1-5ae9e914a2b9/dynamicpage/tab/case/service/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">SC21041500022</a>
                        <!-- <div class="badge badge-danger ml-2">NEW</div> -->
                      </h4>
                      <span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
                <div class="vertical-timeline-item dot-info vertical-timeline-element">
                  <div>
                    <span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">
                        SAFE-C assessment is due in 2 days for Case# <a [routerLink]="['/pages/dynamic-routing/9f050372-6423-4836-a370-1d2e20119d35/dynamicpage/tab/case/IR/page/modify/6651ebf1-4aab-45d1-9514-748a56caf147']">IR21041500029</a></h4>
                        <span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="todo-notif-wrp" *ngIf="user?.role?.rolekey === 'IW'">
          <h3 class="drawer-heading">To Do List</h3>
          <div class="drawer-section p-0">
            <div class="todo-box">
              <ul class="todo-list-wrapper list-group list-group-flush">
                <li class="list-group-item">
                  <div class="todo-indicator bg-warning"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox1266"
                            class="custom-control-input"><label for="exampleCustomCheckbox1266"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Complete Investigation Summary for Intake# <a [routerLink]="['/pages/intake/df20a274-e251-4478-ba85-7ed32379a12f/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130255</a></div>
                        <!-- <div class="widget-subheading">This task has show on hover actions!</div> -->
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-focus"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox1666"
                            class="custom-control-input"><label for="exampleCustomCheckbox1666"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Notify the caregiver(s) of service recommendations for Intake# <a [routerLink]="['/pages/intake/488a0258-4c85-4f06-99e7-c392074c851a/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130258</a></div>
                        <!-- <div class="widget-subheading">This task has show on hover actions!</div> -->
                      </div>
                      <!-- <div class="widget-content-right widget-content-actions">
                        <div class="d-inline-block">
                          <button type="button" class="btn btn-focus btn-transition">
                            <fa-icon [icon]="faEllipsisH"></fa-icon>
                          </button>
                        </div>
                      </div> -->
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-primary"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox4777"
                            class="custom-control-input"><label for="exampleCustomCheckbox4777"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Document intial contact with family members for Intake# <a [routerLink]="['/pages/intake/5fad2123-9ef5-4b73-b4d0-f78c2013360b/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130250</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-info"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2444"
                            class="custom-control-input"><label for="exampleCustomCheckbox2444"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Update Service Log for Intake# <a [routerLink]="['/pages/intake/16a6e22f-ba9f-4d99-9726-5182720ffb58/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130256</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-sm btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-sm btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="todo-indicator bg-info"></div>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">
                        <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2444"
                            class="custom-control-input"><label for="exampleCustomCheckbox2444"
                            class="custom-control-label">&nbsp;</label></div>
                      </div>
                      <div class="widget-content-left flex2">
                        <div class="widget-subheading">Create Safety Plan for Intake# <a [routerLink]="['/pages/intake/61e9b4ef-ebe6-4cb3-a3b1-968274d8a266/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130261</a></div>
                      </div>
                      <div class="widget-content-right widget-content-actions">
                        <button class="border-0 btn-transition btn btn-sm btn-outline-success">
                          <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button class="border-0 btn-transition btn btn-sm btn-outline-danger">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <h3 class="drawer-heading">Notifications</h3>
          <div class="drawer-section">
            <div class="notifications-box">
              <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--one-column">
                <div class="vertical-timeline-item dot-danger vertical-timeline-element">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">Person Added by Leon, Roy for Intake# <a [routerLink]="['/pages/intake/5fad2123-9ef5-4b73-b4d0-f78c2013360b/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130250</a> </h4><span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
                <div class="vertical-timeline-item dot-success vertical-timeline-element">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">Intake approved by Leon, Roy for Intake# <a [routerLink]="['/pages/intake/812c7d30-0fea-44b0-82ad-3ed9eadbeaa4/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130262</a>
                        <!-- <div class="badge badge-danger ml-2">NEW</div> -->
                      </h4>
                      <span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
                <div class="vertical-timeline-item dot-info vertical-timeline-element">
                  <div>
                    <span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <h4 class="timeline-title">
                        Decision 2 days for Intake# <a [routerLink]="['/pages/intake/05a5de6e-0d24-47a4-b37e-c353ff2f0550/mergepage/25dbea0c-9ec0-4a18-aeea-2d408ea045ca']">I211130252</a></h4>
                        <span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </section>
  </div>
</div>

<div class="app-drawer-overlay" (click)="toggleDrawer()" ></div>