import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LocalService } from '../services/local.service'

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private storage: LocalService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storage.getObj('token');
    const accessToken = this.storage.getObj('accesstoken');
    const user = this.storage.getObj('user');
    if ((request?.headers?.get('Authorization') !== 'Basic SERCX0FETUlOOnBhc3N3b3Jk') && request?.headers?.get('Authorization') !== 'pd3hmJAdTQSsTUs7OMImDQ') {
      if (authToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
           
          }
        });

      }
      if (accessToken) {
        request = request.clone({
          setHeaders: {
            accessToken: `${accessToken}`,
           
          }
        });

      }
      if (user) {
        request = request.clone({
          setHeaders: {
            uniqueid: `${user.id}`,

          }
        });
      }
      if (user && user.role) {
        request = request.clone({
          setHeaders: {
            rolekey: `${user.role.rolekey}`,
          }
        });
      }
      if (user && user.userWorkInfo && user.userWorkInfo.organization && user.userWorkInfo.organization.id) {
        request = request.clone({
          setHeaders: {
            organizationid: `${user.userWorkInfo.organization.id}`,
          }
        });
      }
    } else if (request?.headers?.get('Authorization') === 'pd3hmJAdTQSsTUs7OMImDQ') {
      request = request.clone({
        setHeaders: {
          "X-Requested-By": 'bd9c355d-d397-4960-bb5a-fb61e4bf75a4',
          "Accept": "*/*",
        }
      });
      request = request.clone({ headers: request.headers.delete('Authorization','pd3hmJAdTQSsTUs7OMImDQ') });
    }
    return next.handle(request);
  }
}
