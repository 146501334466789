import 'rxjs/Rx';

import { EventEmitter, Injectable } from '@angular/core';
import { LocalService } from './local.service';
import { AlertService } from './alert.services';
import { CommonUrlConfig } from '../common/URLS/common-url.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReplaySubject,Subject } from 'rxjs';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { BrowserService } from './browser.service';
import { PortConfig } from '../common/URLS/port.config';
import { HttpService } from './http.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();
    public chatEnabled: EventEmitter<any> = new EventEmitter<any>();
    public authListener: Subject<any> = new Subject();

    constructor(private browserstore: BrowserService,private localstore: LocalService,
      private http: HttpClient, private router: Router, private alert: AlertService,
      private httpService:HttpService,private dialogRef: MatDialog ) {
    }

    appLogin(user:any) {
      this.httpService.outsideShowSpinner.next(true);
      this.http.post<any>(environment.baseUrl + PortConfig.port['Auth'].id+'/'+CommonUrlConfig.EndPoint.auth.appLogin,{
        'email':user.user.trim(),
        'password':user.password
      }).subscribe(response => {
        this.httpService.outsideShowSpinner.next(false);
        this.localstore.setObj('accesstoken',response.accessToken);
        this.localstore.setObj('token',response.idToken);
        this.browserstore.setObj('accesstoken',response.accessToken);
        this.browserstore.setObj('token', response.idToken);
        this.httpService.outsideShowSpinner.next(false);
        this.isAuthenticatedSubject.next(true);
        this.userDataPopulate(response.idToken);

      },(error)=> {
        this.handleErrror(error);
        console.log(error);
        this.httpService.outsideShowSpinner.next(false);
       });
    }

    appChangePassword(user:any) {
      this.httpService.outsideShowSpinner.next(true);
      this.http.post<any>(environment.baseUrl + PortConfig.port['Auth'].id+'/'+CommonUrlConfig.EndPoint.auth.changePassword,{
        'email':user.email,
        'previousPassword':user.previousPassword,
        'currentPassword':user.currentPassword,
        'token':user.token
      }).subscribe(response => {
        this.httpService.outsideShowSpinner.next(false);
        this.alert.success('Password Changed Successfully');

      },(error)=> {
        console.log(error);
        this.httpService.outsideShowSpinner.next(false);
        this.handleErrror(error);
      });
    }

    appForgotPassword(user:any) {
      this.httpService.outsideShowSpinner.next(true);
      this.http.post<any>(environment.baseUrl + PortConfig.port['Auth'].id+'/'+CommonUrlConfig.EndPoint.auth.appForgotPassword,{
        'email':user.user.trim()
      }).subscribe(response => {
        this.httpService.outsideShowSpinner.next(false);
        this.alert.success('Code Successfully sent');

      },(error)=> {
        console.log(error);
        this.httpService.outsideShowSpinner.next(false);
        this.handleErrror(error);
      });
    }
    appForgotConfirmPassword(user:any) {
      this.httpService.outsideShowSpinner.next(true);
      const username =  user.user;
        const password = user.password;
        const repassword = user.repassword;
        if(password===repassword){
                const otp = user.otp;
                const data = {
                  "email": user.user,
                  "password":user.password,
                  "confirmationCode":otp,
                }
                this.http.post<any>(environment.baseUrl + PortConfig.port['Auth'].id+'/'+CommonUrlConfig.EndPoint.auth.confirmForgotPassword,data).subscribe(response => {
                  this.httpService.outsideShowSpinner.next(false);
                  this.authListener.next('PASSWORD-UPDATED');
                  this.alert.success('Passwore Successfully changed !!! ')

                },(error)=> {
                  console.log(error);
                  this.httpService.outsideShowSpinner.next(false);
                  this.handleErrror(error);
                });
        } else {
            this.alert.error('Password does not match.')
        }

    }

    handleErrror(error) {
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          this.refreshPage();
        } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            if(error.error && error.error.message) {
              this.alert.error(error.error.message);
            } else {
              this.refreshPage();
            }
            

        }
    } else {
      this.refreshPage();
    }
    }


    async login (user: any) {
      this.httpService.outsideShowSpinner.next(true);
        const username =  user.user.trim();
        const password = user.password;
        let message = "";
        let currentAttempt = 0;
        try {
          const loginAttempt = await this.loginAttemptValidation(username);

          if (!loginAttempt || !Object.keys(loginAttempt)?.length) {
            this.httpService.outsideShowSpinner.next(false);
            this.alert.error("Incorrect username");
            return;
          }

          if (!loginAttempt?.isactive) {
            this.httpService.outsideShowSpinner.next(false);
            this.alert.error("User is not Active. Please Contact administrator");
            return;
          } else {
            if (loginAttempt?.isattemptExceeds && loginAttempt?.message) {
              this.httpService.outsideShowSpinner.next(false);
              this.alert.error(loginAttempt?.message);
              return;
            }
            if (!loginAttempt?.isattemptExceeds && loginAttempt?.message) {
              message = loginAttempt?.message
              currentAttempt = loginAttempt?.attempt;
            }
          }
          await Auth.signIn(username, password);
          Auth.currentSession().then(res => {
            this.isAuthenticatedSubject.next(true);
            this.localstore.setObj('token',res.getIdToken().getJwtToken());
            this.browserstore.setObj('token', res.getIdToken().getJwtToken());
            this.httpService.outsideShowSpinner.next(false);
            this.userDataPopulate(res.getIdToken().getJwtToken());
          })

        } catch (err) {
          if(err.code === 'LimitExceededException'){
               this.alert.error('Attempt limit exceeded, please try after some time.')
          }else if (err.code === 'UserNotConfirmedException') {
            await Auth.resendSignUp(username)
          } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            this.loginAttemptUpdation(username);
            if (currentAttempt > 0) {
              message?.split('\n')?.reverse().forEach(ele => {
                this.alert.warn(ele);
              });
              this.alert.error( 'Incorrect username or password.');
            } else {
              this.alert.error( 'Incorrect username or password.');
            }
          } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            this.alert.error('Login failed.')
          } else {
            this.alert.error( 'An error has occurred.')
            // console.error(err)
          }
          this.httpService.outsideShowSpinner.next(false);
        }
      }
      /// TODO
      async refreshToken(){
        Auth.currentSession().then(res => {
            const refreshToken = res.getRefreshToken().getToken();

        })
      }

      async sendCode (user: any) {
        const username =  user.user;
        try {
           await Auth.forgotPassword(username);
           this.alert.success('Code Successfully sent')
        } catch (err) {
            if(err.code === 'LimitExceededException')
               this.alert.error('Attempt limit exceeded, please try after some time.');
        }
      }






      async signUP (form: any) {

        const username =  form.email;
        const password = form.password;
        const name =  form.displayname;
        try {
           await Auth.signUp({
            username: username,
            password: password,
            attributes: {
                email: username,
                phone_number: '+18603138457',
                name:name

            }
        });
           this.alert.success('Cognito Sign Up completed ')
        } catch (err) {
            if(err.code === 'LimitExceededException')
               this.alert.error('Attempt limit exceeded, please try after some time.');
        }
      }


      async confirmPassword (user: any) {
        const username =  user.user;
        const password = user.password;
        const repassword = user.repassword;
        if(password===repassword){
                const otp = user.otp;
                try {
                    await Auth.forgotPasswordSubmit(username,otp,password);
                    this.alert.success('Passwore Successfully changed !!! ')
                } catch (err) {
                    if(err.code === 'LimitExceededException')
                    this.alert.error('Attempt limit exceeded, please try after some time.')
                }
        } else {
            this.alert.error('Password does not match.')
        }
      }




      async logout() {
        await Auth.signOut();
        this.localstore.clear();
        this.browserstore.clear();
        this.isAuthenticatedSubject.next(false);
        this.router.navigate(['/']);
        this.dialogRef.closeAll();
        this.dialogRef.closeAll();
        window.location.reload() 

    }

    refreshPage() {
      this.alert.warn('Application refreshed, please try again..')
      setTimeout(()=> window.location.reload(),1000); 
    }

    public userDataPopulate(id){
        this.setUserFromToken(id).subscribe((result) => {
            const user:any =result;
            if (user && user.userWorkInfo && user.userWorkInfo.organization.id === '25b29508-2faf-48c8-8d70-c155edd3bcee')
            {
                this.sendMessage(user)
                this.router.navigate(['/pages/fleet-dashboard']);
            } else {
                this.router.navigate(['/pages/view-dashboard']);
            }

            if (user?.noofloginattempt > 0) {
              this.http.post<any>(environment.baseUrl + PortConfig.port['menu'].id+'/'+CommonUrlConfig.EndPoint.auth.user+'s/resetAttempt/'+user.email,
                {}, {
                  headers: new HttpHeaders({
                  Authorization: `Bearer ${id}`,
                  uniqueid: "91f71a10-1c5a-4d68-b6e6-8d8af9be44c2"
                })
              }).subscribe(result);
            }

        }, error => {
            console.log(error);
            this.router.navigate(['/login']);
            this.alert.error("user not found");
            this.router.navigate(['/pages/view-dashboard']);
            // this.router.navigate(['/pages/intake']);
        }
        );
    }

    public setUserFromToken(token) {
      token = `Bearer ${token}`;
      return this.http.post<any>(environment.baseUrl + PortConfig.port['Auth'].id+'/'+CommonUrlConfig.EndPoint.auth.user,
          {}, {
          headers: new HttpHeaders({
              Authorization: token
          })
      }).map(
          (result) => {
            this.localstore.setObj('user', result);
            this.browserstore.setObj('user', result);
            return result;
          }
        );
    }

    public setAuth() {
        this.isAuthenticatedSubject.next(true);
    }

    public getRoleKey(){
       const user = this.localstore.getObj('user');
       if(user && user.role) {
        return user.role.rolekey;
      }
    }

    public getUser() {
      const user = this.localstore.getObj('user');
      return user;
    }

    public getUserDisplayName() {
      const user = this.localstore.getObj('user');
      const userdisplayname = user.firstName+" "+user.lastName;
      return userdisplayname;
    }

    public getUserEmail() {
      const user = this.localstore.getObj('user');
      const useremail = user.email;
      return useremail;
    }

    public getUserPhoneNumber() {
      const user = this.localstore.getObj('user');
      const phone_number = user.phonenumber;
      return phone_number;
    }

    public isAdmin(){
        return "ADM" === this.getRoleKey();
    }

    public getOrgID(){
        const user = this.localstore.getObj('user');
        if(user &&  user.userWorkInfo && user.userWorkInfo.organization && user.userWorkInfo.organization.id ) {
         return user.userWorkInfo.organization.id;
       } else {
        return '';
       }
     }

     public getOrgImage(){
        const user = this.localstore.getObj('user');
        if(user &&  user.userWorkInfo && user.userWorkInfo.organization && user.userWorkInfo.organization.logourl ) {
         return environment.s3BucketUrl + '/' + user.userWorkInfo.organization.logourl;
       } else {
        return '';
       }
     }

     public handleShowChat() {
        console.log("Auth show chat");
        this.chatEnabled.emit();
     }
     sendMessage(userinfo) {
         console.log(userinfo)
         if(self!=top) {
            window.parent.postMessage({"user":userinfo},'http://localhost')
            }


      }

      private loginAttemptUpdation(email) {
        Auth.signIn(environment.tempUsername, environment.tempPassword).then((result) => {
          Auth.currentSession().then(res => {
            const token = res.getIdToken().getJwtToken();
            // this.http.post<any>('http://localhost:9004/api/users/'+email,
            this.http.post<any>(environment.baseUrl + PortConfig.port['menu'].id+'/'+CommonUrlConfig.EndPoint.auth.user+'s/'+email,
              {}, {
                headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                uniqueid: "91f71a10-1c5a-4d68-b6e6-8d8af9be44c2"
              })
            }).subscribe(result => {
              console.log('--Login Attempt-Updated----',result+1);
              Auth.signOut().then();
            }, error => {
              Auth.signOut().then();
            });
          })
        });
      }

      private async loginAttemptValidation(email) {
        await Auth.signIn(environment.tempUsername, environment.tempPassword);
        let loginAttemptObj:any = {};
        try {
          const getCurrentSession = await Auth.currentSession();
          const token = getCurrentSession.getIdToken().getJwtToken();
          // this.http.post<any>('http://localhost:9004/api/users/getAttempt/'+email,
          // this.http.post<any>(environment.baseUrl + PortConfig.port['menu'].id+'/'+CommonUrlConfig.EndPoint.auth.user+'s/getAttempt/'+email,
          loginAttemptObj = await this.http.post<any>(environment.baseUrl + PortConfig.port['menu'].id+'/'+CommonUrlConfig.EndPoint.auth.user+'s/getAttempt/'+email,
                {}, {
                  headers: new HttpHeaders({
                  Authorization: `Bearer ${token}`,
                  uniqueid: "91f71a10-1c5a-4d68-b6e6-8d8af9be44c2"
                })
              }).toPromise();
        } catch(e) {
          console.log(e);
        }
        await Auth.signOut()
        return loginAttemptObj;
      }

      public getAuthHeaders() {
        const user = this.localstore.getObj('user');
        return new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.localstore.getObj('token')}`,
          uniqueid: `${user.id}`,
          organizationid: `${user.userWorkInfo.organization.id}`,
          rolekey: `${user.role.rolekey}`,
          'role': 'role=CP_PUBLIC'
        })
      }
}
