import { Injectable } from '@angular/core';
import { ListDataItem } from '../entities/common.entities';
import { HttpService } from './http.service';
import { map } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import { PortConfig } from '../common/URLS/port.config';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {
  endpointUrl: string;
  baseUrl = '';
  constructor(public http: HttpService) {
    this.baseUrl = environment.baseUrl + environment.apiHost;
  }



  getAll(url?: string, port = null) {
    const request = this.http.get(this.getUrl(url, port));
    return request.map((result: ListDataItem<any>) => {
      return result;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  getById(id: number | string, url?: string, port = null) {
    let path = ''
    /**
     * Added this condition to append the ID after '{id}' if the URL contains strings after {id}/
     */
    if (url.split("'{id}'")[1]) {
      url = url.split("'{id}'")[0] +"'{id}'"+"/"+id+ url.split("'{id}'")[1]
      path = `${this.getUrl(url, port)}`;
    } else {
      path = `${this.getUrl(url, port)}/${id}`;
    }
    return this.http.get(path).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  getByIdAndOtherParam(id: number | string,other: number | string, url?: string, port = null) {
    return this.http.get(`${this.getUrl(url, port)}/${id}/${other}`).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  create(item: any, url?: string, port = null) {
    return this.http.post(this.getUrl(url, port), JSON.stringify(item)).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }
  save(item: any, url?: string, port = null) {
    return this.http.post(this.getUrl(url, port), JSON.stringify(item));
  }

  update(id: string, item: any, url?: string, port = null) {
    return this.http.put(`${this.getUrl(url, port)}/${id}`, JSON.stringify(item)).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }
  updateNoSlash(item: any, url?: string, port = null) {
    return this.http.put(`${this.getUrl(url, port)}`, JSON.stringify(item)).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }
  updateWithoutid(item: any, url?: string, port = null) {
    return this.http.put(this.getUrl(url, port), item).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  patch(id: string, item: any, url?: string) {
    return this.http.patch(`${this.getUrl(url)}/${id}`, JSON.stringify(item)).map((data: any) => {
      return data;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  remove(id: number | string, item?: any, url?: string, port = null) {
    return this.http.delete(`${this.getUrl(url, port)}/${id}`, JSON.stringify(item)).map((response: string) => {
      return response;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  deleteByPost(id: number | string, item?: any, url?: string) {
    return this.http.post(`${this.getUrl(url)}`, JSON.stringify(item)).map((response: any) => {
      return response;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  getUrl(url: string, port?) {
    let portData = port?PortConfig.port[port].id:''
    let fullUrl = `${this.baseUrl.replace('port', portData)}/${url}`;    
    if(!port){
      // this.baseUrl = environment.baseUrl;
      
      if(url.startsWith('tesla/')) {
        // temp fix for calling external url
        fullUrl = environment.teslaBaseUrl + url.replace('tesla/','');
      } else {
        fullUrl = `${environment.baseUrl}${url}`;
      }
    }
    return fullUrl ? fullUrl : this.endpointUrl;
  }


  getArrayList(options: any, url?: string, port = null) {
    let request: any;
    if (!options.method) {
      request = this.http.get(`${this.getUrl(url, port)}`);
    } else if (options.method === 'get') {
      request = this.http.get(`${this.getUrl(url, port)} =` + JSON.stringify(options));
    } else if (options.method === 'post') {
      request = this.http.post(`${this.getUrl(url, port)}`, JSON.stringify(options));
    }
    return request.map((result: any[]) => {
      return result;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  downloadFile(url?: string, type?:string) {
    return this.http.get(url, {
      responseType: 'arraybuffer',
      headers: new HttpHeaders().append('Content-Type', 'application/pdf'),
    });
  }

  callExternalApi(options: any, url?: string) {
    const method = options?.method ? options?.method : 'GET';
    return this.http.externalAPI(method, url, options?.body, options?.params, (options?.responseType ? options?.responseType : null), options?.headers).map((result: any) => {
      return result;
    }, (err) => {
      console.log(err);
      // this.error.error(err);
    });
  }

  
}
