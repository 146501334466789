import { Component, ElementRef, NgZone, TemplateRef, ViewChild, OnInit, Optional, Inject } from '@angular/core';
import * as Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { T } from '@angular/cdk/keycodes';
import { LocalService } from 'src/app/@core/services/local.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentViewerComponent } from 'src/app/shared/modules/document-viewer/document-viewer.component';
import { TemplateBuilderService } from 'src/app/@core/services/template-builder.service';
import { LoaderComponent } from 'src/app/shared/modules/loader/loader.component';
import { AlertService } from 'src/app/@core/services/alert.services';
import { AuthService } from 'src/app/@core/services/auth.service';
@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent implements OnInit {
  dlid: any;
  isDLStatusChecking: boolean = false;
  dlStatus: any;
  dlName: any;
  TRUNCATE_TEXT_LENGTH = 53;
  isValidTemplate: boolean = false;
  templateFormGroup: FormGroup;
  replaceKeyValueMapping: any;
  htmlcontent: any;
  popupTitle: any;
  popupData: any;
  popupPosition: any;
  formId: any;
  user: any;
  @ViewChild('readMoreDialogPopup') readMoreDialogPopup: TemplateRef<any>;
  @ViewChild('demandLetterStatusPopup') demandLetterStatusPopup: TemplateRef<any>;
  @ViewChild('demandLetterErrorStatusPopup') demandLetterErrorStatusPopup: TemplateRef<any>;
  @ViewChild('confirmSelectedAttachments') confirmSelectedAttachments: TemplateRef<any>;
  private attachmentDialogRef: MatDialogRef<TemplateRef<any>>;
  popupDialogRef: MatDialogRef<TemplateRef<any>>;
  jsonForm: any;
  selected_attachments: any = [];
  claimInfo: any;
  submittedData: any = [];
  attachments: any;
  claim_data_expanded: boolean = true;
  attachments_list_expanded: boolean = true;
  sourceid: any;
  letter_template: any = "";
  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'bullet' }]]
  };
  rbacValue: any;
  pageLoaded: boolean = false;
  config: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: '35rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  selectedState: String;
  template_types_list: any;
  template_type:any;
  template_name:any;
  dlpopupTitle: any;
  dlpopupData: any;
  dlpopupDialogRef: MatDialogRef<any, any>;
  dlErrorPopupTitle: any;
  dlErrorPopupData: any;
  dlErrorPopupDialogRef: MatDialogRef<any, any>;
  checkLimit: number;
  constructor(private formBuilder: FormBuilder, private commonHttpService: CommonHttpService, private zone: NgZone, private element: ElementRef,
    private route: ActivatedRoute, public dialog: MatDialog,
    private localstorage: LocalService,
    private templateBuilder: TemplateBuilderService,
    public dialogRef: MatDialogRef<TemplateBuilderComponent>,
    public loaderDialogRef: MatDialogRef<LoaderComponent>,
    private _date: DatePipe,
    private alert: AlertService,
    private auth: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
    this.template_types_list = {"demand_letter":"Demand Letter","notice_of_subrogation":"Notice of Subrogation","total_loss":"Total Loss"};
    this.dialogRef.disableClose = true;
    this.popupPosition = { of: window, at: "top", my: "top", offset: { y: 10 } };
    this.selectedState = "";
    this.dialogRef.disableClose = true;
    this.user = this.localstorage.getObj('user');
    this.replaceKeyValueMapping = {
      "$_.letter_date._$": "<b> << Letter Date >> </b>",
      "$_.claim_id._$": "<b> << Tesla Claim Id >> </b>",
      "$_.to_insurance_company._$": "<b> << To Insurance Company >> </b>",
      "$_.to_person_name._$": "<b> << To Person Name >> </b>",
      "$_.to_address_line1._$": "<b> << To Address Line1 >> </b>",
      "$_.to_city._$": "<b> << To City >> </b>",
      "$_.to_state._$": "<b> << To State >> </b>",
      "$_.to_pincode._$": "<b> << To Pincode >> </b>",
      "$_.to_mailid._$": "<b> << To MailId >> </b>",
      "$_.to_claim_id._$": "<b> << To Claim Id >> </b>",
      "$_.to_insured._$": "<b> << To Insured >> </b>",
      "$_.date_of_loss._$": "<b> << Date of Loss >> </b>",
      "$_.tesla_insured._$": "<b> << Tesla Insured >> </b>",
      "$_.subrogation_total._$": "<b> << Subrogation Total >> </b>",
      "$_.tesla_model._$": "<b> << Tesla Model >> </b>",
      "$_.deductible_amount._$": "<b> << Deductible Amount >> </b>",
      "$_.due_date_count._$": "<b> << Due Date >> </b>",
      "$_.payment_to_payable._$": "<b> << Payment to Payable >> </b>",
      "$_.payment_to_mail._$": "<b> << Payment to Mail >> </b>",
      "$_.payment_to_addressline1._$": "<b> << Payment to Addressline1 >> </b>",
      "$_.payment_to_addressline2._$": "",
      "$_.payment_to_city._$": "<b> << Payment to City >> </b>",
      "$_.payment_to_state._$": "<b> << Payment to State >> </b>",
      "$_.payment_to_pincode._$": "<b> << Payment to Pincode >> </b>",
      "$_.payment_to_note._$": "<b> << Payment to Note >> </b>",
      "$_.from_name._$": "<b> << From Name >> </b>",
      "$_.from_designation._$": "<b> << From Designation >> </b>",
      "$_.from_mobile._$": "<b> << From Mobile >> </b>",
      "$_.from_mailid._$": "<b> << From MailId >> </b>",
      "$_.reimbursement_per._$": "<b> << Reimbursement >> </b>",
      "$_.from_company._$": "DMI Inc.",
      "$_.claim_insured_name._$":"<< Claim Insured Name>>",
      "$_.actual_cash_value._$":"0",
      "$_.taxes._$":"0",
      "$_.reg_title._$":"0",
      "$_.sca_inspection._$":"0",
      "$_.rental._$":"0",
      "$_.rental_oop._$":"0",
      "$_.iaa_charges._$":"0",
      "$_.salvage._$":"0",
      "$_.total_demand_amount._$":"0",
      "$_.othercharges_html._$": ""


    };
    if (this.data && this.data.sourceid) {
      this.sourceid = this.data.sourceid;
      this.template_type = this.data.type;
      if(this.template_type !== null) {
        this.template_name = this.template_types_list[this.template_type];
        if(this.template_type == 'notice_of_subrogation') {
          this.formId= "ca60eefb-7aa2-4d19-b592-5b1241a7d00d";
          // this.formId = "d507916b-2f1f-4cfb-8a90-fdf2c0aa25c0";
        } else if(this.template_type == 'demand_letter' )  {
          this.formId= "fb66bc8a-6175-491e-957d-0da39a3c4fba";
        } else if(this.template_type == 'total_loss' )  {
          this.formId= "fb66bc8a-6175-491e-957d-0da39a3c4fba";
        }
      }
    } else {
      this.sourceid = this.route.parent.parent.parent.parent.snapshot.paramMap.get('sourceid');
    }

    this.getClaiminfo();

    this.loadForm();
    this.getLetterTemplate();
    this.getAttachments();
    if(this.template_type == 'total_loss') {
      this.load_total_loss();
    }

  }

  load_total_loss() {
    let curr_state = "CA";
    let values = {};
    this.commonHttpService.getAll(`tesla/api/v1/template/letter?letter_type=`+this.template_type+`&state=` + curr_state)
      .subscribe(data => {
        if (data['template'] && data['template'] !== '') {
          this.letter_template = data['template'];
          this.GetTotalLoss(this.sourceid);
          // this.update_template_values(this.letter_template, values);
          this.isValidTemplate = true;

        } else {
          let error_template = data['error'];
          // this.update_template_values(error_template, event.data);
          this.isValidTemplate = false;
        }
      }, (error) => {
        console.log(error);
      });
  }

  popup(data, title) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      height: '50%',
      width: '50%',
    };
    this.popupDialogRef = this.dialog.open(this.readMoreDialogPopup, dialogConfig);
    // tslint:disable-next-line: prefer-for-of
    this.popupTitle = title;
    this.popupData = data;
  }

  closeDialogPopup() {
    this.popupDialogRef.close();
  }
  getTemplate() {
    this.jsonForm = null;
    let url = `applicationaccess/${this.user.id}/${this.user.role.id}?modulekey=&submodulekey=&pageid=${this.formId}`;
    this.commonHttpService
      .getArrayList({}, url, 'rbac')
      .subscribe((result) => {
        if (result) {
          if (result[0].templatejson) {
            result[0].templatejson = result[0].templatejson.replaceAll("{sourceid}", this.sourceid);
            this.jsonForm = JSON.parse(result[0].templatejson);
            let formTemplateJson = JSON.parse(JSON.stringify(this.jsonForm));
          }

        }
        const vehicleYear = this.claimInfo['vehicle_year'] ? this.claimInfo['vehicle_year']+ " " : ''
        const vehicleMake = this.claimInfo['vehicle_make'] ? this.claimInfo['vehicle_make']+ " " : ''
        const vehicleModel = this.claimInfo['vehicle_model'] ? this.claimInfo['vehicle_model'] : ''
        this.submittedData = { data: {} };
        this.submittedData.data.claim_insured_name = this.claimInfo['claim_insured_name'];
        this.submittedData.data.policy_rating_state = this.claimInfo['policy_rating_state'];
        this.submittedData.data.claim_id = this.claimInfo['claim_id'];
        this.submittedData.data.date_of_loss = this.claimInfo['claim_loss_date'];
        this.submittedData.data.tesla_model = vehicleYear +  vehicleMake + vehicleModel;
        this.submittedData.data.tesla_insured = this.claimInfo['claim_insured_name'];
        this.submittedData.data.payment_to_payable = this.claimInfo['insurance_carrier'];
        this.submittedData.data.payment_to_mail = "Subrogation Strategies/" + this.claimInfo['insurance_carrier'];
        this.submittedData.data.payment_to_addressline1 = "2001 Market Street";
        this.submittedData.data.payment_to_addressline2 = "Suite 2900";
        this.submittedData.data.payment_to_city = "Philadelphia";
        this.submittedData.data.payment_to_state = "PA";
        this.submittedData.data.payment_to_pincode = "19103";
        this.submittedData.data.payment_to_note = "Attention: please reference our claim # on the check.";
        this.submittedData.data.due_date_count = 30;
        this.submittedData.data.from_name = this.auth.getUserDisplayName();
        this.submittedData.data.from_designation = "Subrogation Analyst";
        this.submittedData.data.from_mailid = this.auth.getUserEmail();
        this.submittedData.data.from_mobile = this.auth.getUserPhoneNumber();
        this.submittedData.data.from_company="DMI Inc.";

        // this.submittedData.data.deductible_amount= this.claimInfo['comp_deductible'];
        // this.submittedData.data.subrogation_total= parseFloat(this.claimInfo['claim_total_incurred_after_recovery']) + parseFloat(this.claimInfo['comp_deductible']);
        this.submittedData.data.other_insurance_carriers = this.claimInfo['other_insurance_carriers'];
        // this.submittedData.data.to_insurance_company = "Claimant";
        if(this.template_type !== 'total_loss' )  {
        this.getCacheInfo();
        }
      }, (error) => {
        console.log(error);
      });

  }

  getCacheInfo () {
    let temp_type = "DemandLetter";
    if(this.template_type == "demand_letter") {
      temp_type = "DemandLetter";
    } else if (this.template_type == "notice_of_subrogation") {
      temp_type = "NoticeofSubrogation";
    }
    this.commonHttpService.getAll(`tesla/api/v1/claim/${this.sourceid}/cache/`+temp_type)
    .subscribe((data: any) => {
      if (data.length !== 0) {
        const cache_data = data['cache_data'];
        if (cache_data['attachment'] && cache_data['attachment'].length !== 0) {
          this.selected_attachments = cache_data['attachment'];
        }
          this.submittedData = { data: {} };
          this.submittedData.data = cache_data['form_data'].data;
          this.submittedData.data.other_insurance_carriers = this.claimInfo['other_insurance_carriers'];
      } else {
          const vehicleYear = this.claimInfo['vehicle_year'] ? this.claimInfo['vehicle_year']+ " " : ''
          const vehicleMake = this.claimInfo['vehicle_make'] ? this.claimInfo['vehicle_make']+ " " : ''
          const vehicleModel = this.claimInfo['vehicle_model'] ? this.claimInfo['vehicle_model'] : ''
          this.submittedData = { data: {} };
          this.submittedData.data.policy_rating_state = this.claimInfo['policy_rating_state'];
          this.submittedData.data.claim_id = this.claimInfo['claim_id'];
          this.submittedData.data.date_of_loss = this.claimInfo['claim_loss_date'];
          this.submittedData.data.tesla_model = vehicleYear +  vehicleMake + vehicleModel;
          this.submittedData.data.tesla_insured = this.claimInfo['claim_insured_name'];
          this.submittedData.data.payment_to_payable = this.claimInfo['insurance_carrier'];
          this.submittedData.data.payment_to_mail = "Subrogation Strategies/" + this.claimInfo['insurance_carrier'];
          this.submittedData.data.payment_to_addressline1 = "2001 Market Street";
          this.submittedData.data.payment_to_addressline2 = "Suite 2900";
          this.submittedData.data.payment_to_city = "Philadelphia";
          this.submittedData.data.payment_to_state = "PA";
          this.submittedData.data.payment_to_pincode = "19103";
          this.submittedData.data.payment_to_note = "Attention: please reference our claim # on the check.";
          this.submittedData.data.due_date_count = 30;
          // this.submittedData.data.deductible_amount= this.claimInfo['comp_deductible'];
          // this.submittedData.data.subrogation_total= parseFloat(this.claimInfo['claim_total_incurred_after_recovery']) + parseFloat(this.claimInfo['comp_deductible']);
          this.submittedData.data.other_insurance_carriers = this.claimInfo['other_insurance_carriers'];
          this.submittedData.data.from_name = this.auth.getUserDisplayName();
          this.submittedData.data.from_designation = "Subrogation Analyst";
          this.submittedData.data.from_mailid = this.auth.getUserEmail();
          this.submittedData.data.from_mobile = this.auth.getUserPhoneNumber();
          this.submittedData.data.from_company="DMI Inc.";
      }

    }, (error) => {
      console.log(error);
    });
  }

  getClaiminfo() {
    this.commonHttpService.getAll(`tesla/api/v1/claim/${this.sourceid}`)
      .subscribe(data => {
        this.claimInfo = data;
        this.getTemplate();
        console.log("RRRRRRROOOOUTE", data['claim_id']);

      }, (error) => {
        console.log(error);
      });
  }


  expand_claim_panel() {
    this.claim_data_expanded = true;
  }

  collapse_claim_panel() {
    this.claim_data_expanded = false;
  }

  expand_attachments_panel() {
    this.attachments_list_expanded = true;
  }

  collapse_attachments_panel() {
    this.attachments_list_expanded = false;
  }
  update_template_values(template, info) {
    let updatedTemplate = template;

    updatedTemplate = this.templateBuilder.update_template_values(template, info, '$_.', '._$')
    console.log("updatedTemplate",updatedTemplate);
    console.log("template",template)
    console.log("info",info)
    this.setHTML(updatedTemplate);
  }

  setHTML(updatedTemplate) {
    let finalTemplate = updatedTemplate;
    for (const key in this.replaceKeyValueMapping) {
      // console.log(key, this.replaceKeyValueMapping[key]);
      finalTemplate = finalTemplate.replaceAll(key, this.replaceKeyValueMapping[key]);
    }

    this.templateFormGroup.get('htmlcontent').setValue(finalTemplate);
  }

  getLetterTemplate() {
    this.pageLoaded = true;
    this.isValidTemplate = false;

  }

  getAttachments() {
    this.commonHttpService.getAll(`tesla/api/v1/claim/${this.sourceid}/attachment`)
      .subscribe(data => {
        this.attachments = data;
        this.selected_attachments = [];
      }, (error) => {
        console.log(error);
      });
    this.selected_attachments = [];

  }

  showSuccessMsg() {
    const loaderdialogConfig = new MatDialogConfig();
    loaderdialogConfig.disableClose = false;
    loaderdialogConfig.data = {
      height: '50%',
      width: '50%',
      maxWidth: '78vw',
      maxHeight: '70vh',
      message: this.template_name+' generated and Available on Notes and Attachment.',
      successMsg: this.template_name+' is Generated Successfully!!',
      showSuccessImage: true,
      showLoadingImage: false,
      position: { top: '10px' }
    };
    let attachmentData = { 's3_path': this.dlName }
    this.viewAttachment(attachmentData);
    setTimeout(() => {
      this.loaderDialogRef = this.dialog.open(LoaderComponent, loaderdialogConfig);
    }, 1000);
    setTimeout(() => {
      this.loaderDialogRef.close();
    }, 5000);

  }

  // generateTemplate(){
  //   if(this.template_type == 'demand_letter') {
  //     this.generateDLTemplate();
  //   } elseif
  // }

  generateTemplate() {
    console.log("Generate Template");
    this.dlStatus = '';
    this.dlName = '';
    this.isDLStatusChecking = false;
    let htmlData = this.templateFormGroup.get('htmlcontent').value;
    let curr_time = new Date();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.height = '90%';
    dialogConfig.width = '100%';
    let data = {
      "type": "html", "html": htmlData, "formData": this.submittedData, "other_attachments": this.selected_attachments, "variables": {
        "claim_id": this.claimInfo['claim_id'],
        "template_type": this.template_type,
        "created_at": this._date.transform(curr_time, 'dd-MM-yyyy HH:mm:ss a'),
        "created_by": this.user.firstName + " " + this.user.lastName,
      }
    };

    // this.selected_attachments = [];
    // this.getAttachments();
    const loaderdialogConfig = new MatDialogConfig();
    loaderdialogConfig.disableClose = true;
    loaderdialogConfig.data = {
      height: '50%',
      width: '50%',
      maxWidth: '78vw',
      maxHeight: '70vh',
      message: 'Generating '+ this.template_name,
      successMsg: this.template_name+' is Generated Successfully!!',
      showSuccessImage: false,
      showLoadingImage: true,
      position: { top: '10px' }
    };
    this.loaderDialogRef = this.dialog.open(LoaderComponent, loaderdialogConfig);
    if(this.template_type !== 'total_loss' )  {
    this.CreateDemandLetterCache();
    }

    this.commonHttpService.create(data, `tesla/api/v1/template/letter/generate`)
      .subscribe(data => {
        let id = data?.id;
        if (id !== null && id.length > 5) {
          this.dlid = id;
          let flag = 0;
          this.getDemandLetterStatus(id);
          let getInterval = setInterval(() => {
            flag = flag + 1;
            this.getDemandLetterStatus(id);
            if (this.dlStatus === 'Completed') {
              clearTimeout(getInterval);
              this.loaderDialogRef.close();
              flag = 0;
              this.showSuccessMsg();
            } else if (this.dlStatus === 'Failed') {
              clearTimeout(getInterval);
              this.loaderDialogRef.close();
              flag = 0;
              this.setError();
            } else {
              if (flag > 6) {
                this.loaderDialogRef.close();
                this.dlPopup();
                flag = 0;
                clearTimeout(getInterval);
              }
            }
          }, 3000);
        }
      }, (error) => {
        console.log(error);
      });
  }

  CreateDemandLetterCache() {
    let temp_type = "DemandLetter";
    if(this.template_type == "demand_letter") {
      temp_type = "DemandLetter";
    } else if (this.template_type == "notice_of_subrogation") {
      temp_type = "NoticeofSubrogation";
    }
    let curr_time = new Date();
    let form_data = {
      "form_data": this.submittedData,
      "attachment": this.selected_attachments
    }
    let claim_cache_data = {
      "claim_id": this.claimInfo['claim_id'],
      "type":temp_type,
      "formData": form_data,
      "created_at": this._date.transform(curr_time, 'dd-MM-yyyy HH:mm:ss a'),
      "created_by": this.user.firstName + " " + this.user.lastName
    }
    console.log('claim_cache_data: ',claim_cache_data);
    this.commonHttpService.create(claim_cache_data, `tesla/api/v1/claim/${this.sourceid}/cache`)
      .subscribe(data => {
        console.log('claim_cache_data: ', data);
      }, (error) => {
        console.log(error);
      });
  }

  showDemandLetterStausPopup() {
    const loaderdialogConfig = new MatDialogConfig();
    loaderdialogConfig.disableClose = true;
    loaderdialogConfig.data = {
      height: '50%',
      width: '50%',
      maxWidth: '78vw',
      maxHeight: '70vh',
      message: 'Generating '+this.template_name,
      successMsg: this.template_name+' is Generated Successfully!!',
      showSuccessImage: false,
      showLoadingImage: false,
      position: { top: '10px' }
    };
    this.loaderDialogRef = this.dialog.open(LoaderComponent, loaderdialogConfig);
  }

  dlPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      height: '70%',
      width: '70%',
    };
    this.dlpopupDialogRef = this.dialog.open(this.demandLetterStatusPopup, dialogConfig);
    // tslint:disable-next-line: prefer-for-of
    this.dlpopupTitle = this.template_name+" Status";
    this.dlpopupData = this.template_name+" is being generated, you may choose to wait by clicking refresh button or close this message and check the "+this.template_name+" in attachments section after a few minutes.";
  }

  closeDLDialogPopup() {
    this.dlpopupDialogRef.close();
  }

  getDemandLetterStatus(id) {
    this.commonHttpService.getAll(`tesla/api/v1/template/letter/status?id=` + id)
      .subscribe(statusInfo => {
        this.dlStatus = statusInfo['status'];
        if (this.dlStatus === 'Completed') {
          this.dlName = statusInfo['name'];
        }
      }, (error) => {
         console.log(error);
      });
  }

  UpdateOtherCharges(statusInfo) {
    let name = '';
    let amount =  '';
    let template = '<table style="margin-left: 10px;width:100%;"><tbody><tr><td style="width:24%;font-style:normal;font-weight:400;font-size:10pt;font-family:Calibri;color:#000000;"><b>Other Charges:</b></td><td style="width:55%;font-style:normal;font-weight:400;font-size:10pt;font-family:Calibri;color:#000000;" ></td></tr>'
    for (let index = 0; index < statusInfo['othercharges'].length ; index++) {
      name = statusInfo['othercharges'][index]['name'];
      amount = statusInfo['othercharges'][index]['amount'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (amount !== '0' && name !== '' ) {
      template += '<tr><td style="width:24%;font-style:normal;font-weight:400;font-size:10pt;font-family:Calibri;color:#000000;">'+name+'</td><td style="width:55%;font-style:normal;font-weight:400;font-size:10pt;font-family:Calibri;color:#000000;" >$  '+amount+'</td>	</tr>'
      }
    }
    template += '</tbody></table>'
    let updatedTemplate = this.letter_template.replaceAll('$_.othercharges_html._$', template);
    this.update_template_values(updatedTemplate, statusInfo);
  }

  GetTotalLoss(id) {
    this.commonHttpService.getAll(`tesla/api/v1/total-loss/` + id)
      .subscribe(statusInfo => {
        // this.submittedData.data.actual_cash_value = statusInfo['actual_cash_value'];
        console.log("statusInfo",statusInfo);
        console.log("letter_template",this.letter_template)
        if (statusInfo['othercharges']?.length > 0 && !(statusInfo['othercharges']?.length == 1 && statusInfo['othercharges'][0]['amount'] == 0)) {
          this.UpdateOtherCharges(statusInfo);
        } else {
        this.update_template_values(this.letter_template, statusInfo);}
      }, (error) => {
         console.log(error);
      });
  }

  checkdemandletterStatus(id) {
    this.isDLStatusChecking = true;
    let self = this;
    self.checkLimit = 3;
    this.getDemandLetterStatus(id);

    let refreshId = setInterval(function () {
      if (self.dlStatus === 'Completed') {
        clearInterval(refreshId);
        self.closeDLDialogPopup();
        self.isDLStatusChecking = false;
        self.showSuccessMsg();
      } else if (self.dlStatus === 'Failed') {
        this.setError();
      }
      if (self.checkLimit <= 2) {
        clearInterval(refreshId);
        self.isDLStatusChecking = false;
      }
      self.getDemandLetterStatus(id);
      self.checkLimit = self.checkLimit - 1;
    }, 3000);
  }

  viewAttachment(data) {
    let encode = encodeURIComponent(data['s3_path']);
    this.commonHttpService.getAll(`tesla/api/v1/claim/1/attachment?key=` +encode)
      .subscribe(result => {
        console.log(result);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.height = '90%';
        dialogConfig.width = '100%';
        result['templateData'] = this.submittedData;
        result['attachment_path'] = data['s3_path'];
        dialogConfig.data = { info: result, action: data };
        const ref = this.dialog.open(DocumentViewerComponent, dialogConfig);
        ref.afterClosed().subscribe(result => {
          console.log("dialog closed", result)
        });
      }, (error) => {
        console.log(error);
      });

  }


  loadForm() {
    this.templateFormGroup = this.formBuilder.group({
      htmlcontent: new FormControl(),
      letter_date: new FormControl(),
      claim_id: new FormControl(),
      to_insurance_company: new FormControl(),
      to_person_name: new FormControl(),
      to_address_line1: new FormControl(),
      to_city: new FormControl(),
      to_state: new FormControl(),
      to_pincode: new FormControl(),
      to_mailid: new FormControl(),
      to_claim_id: new FormControl(),
      to_insured: new FormControl(),
      date_of_loss: new FormControl(),
      tesla_insured: new FormControl(),
      subrogation_total: new FormControl(),
      tesla_model: new FormControl(),
      deductible_amount: new FormControl(),
      due_date_count: new FormControl(),
      payment_to_payable: new FormControl(),
      payment_to_mail: new FormControl(),
      payment_to_addressline1: new FormControl(),
      payment_to_addressline2: new FormControl(),
      payment_to_city: new FormControl(),
      payment_to_state: new FormControl(),
      payment_to_pincode: new FormControl(),
      payment_to_note: new FormControl(),
      from_name: new FormControl(),
      from_designation: new FormControl(),
      from_mobile: new FormControl(),
      from_mailid: new FormControl(),
      from_company: new FormControl()

    });
    this.templateFormGroup.get('htmlcontent').setValue('Loading Template');
  }

  truncateIfExceeds(text) {
    if (text && text.length > this.TRUNCATE_TEXT_LENGTH) {
      text = text.substring(0, this.TRUNCATE_TEXT_LENGTH) + '...';
    }
    return text;
  }

  onChangeForm(event) {
    // event.data.payment_to_note = "Attention: "+ event.data.from_name +"; please reference our claim # on the check.";
    if (event?.data?.policy_rating_state != null){
    let curr_state = event.data.policy_rating_state;
    if (curr_state !== this.selectedState || (curr_state == "" && this.selectedState != "")) {
      this.selectedState = curr_state;
      this.commonHttpService.getAll(`tesla/api/v1/template/letter?letter_type=`+this.template_type+`&state=` + curr_state)
        .subscribe(data => {
          if (data['template'] && data['template'] != '') {
            this.letter_template = data['template'];
            this.update_template_values(this.letter_template, event.data);
            this.isValidTemplate = true;

          } else {
            let error_template = data['error'];
            this.update_template_values(error_template, event.data);
            this.isValidTemplate = false;
          }
        }, (error) => {
          console.log(error);
        });
    } else {
      if (event.data) {
        console.log("ELSE 1")
        console.log(this.jsonForm)
        this.update_template_values(this.letter_template, event.data);
      }
    }
  } else {
    if (event.data) {
      console.log("ELSE 2")
      console.log(this.jsonForm)
      this.update_template_values(this.letter_template, event.data);
    }
  }
  }



  getAttachmentIndex(key) {
    var index = this.selected_attachments.indexOf(key);
    if (index > -1) {
      return index;
    } else {
      return -1;
    }
  }

  toggle_attachment(key) {
    var index = this.selected_attachments.indexOf(key);

    if (index > -1) {
      this.selected_attachments.splice(index, 1);
    } else {
      this.selected_attachments.push(key);
    }
    console.log('toggle_attachment: ',this.selected_attachments);
  }

  attachmetConfirmation(): void {
    this.attachmentDialogRef = this.dialog.open(this.confirmSelectedAttachments);
  }

  removeRecord(): void {
    this.selected_attachments.splice(0);
    this.attachmentDialogRef.close();
  }

  closePopup(): void {
    this.attachmentDialogRef.close();
  }

  close() {
    console.log('close event trigger');
    if(this.template_type !== 'total_loss') {
    this.CreateDemandLetterCache();
    }
    this.dialogRef.close();
  }

  setError() {
    const errorDialogConfig = new MatDialogConfig();
    errorDialogConfig.disableClose = true;
    errorDialogConfig.data = {
      // minHeight : '70vh',
      minWidth: '70vw',
    };
    this.dlErrorPopupDialogRef = this.dialog.open(this.demandLetterErrorStatusPopup, errorDialogConfig);
    // tslint:disable-next-line: prefer-for-of
    this.dlErrorPopupTitle = this.template_name+' Generation';
    this.dlErrorPopupData = this.template_name+' generation failed, please try to generate the letter in few minutes. Please contact the technical support, if problem persists.';
  }

  closeDLErrorDialogPopup() {
    this.dlErrorPopupDialogRef.close();
  }

}
