import { Component, ElementRef, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { LocalService } from './@core/services/local.service';
import { AuthService } from './@core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Http } from '@angular/http';
import { CommonHttpService } from './@core/services/common-http.service';
// Commented > to stop polling chat
/*import { Socket } from 'ngx-socket-io';
import { ChatAdapter } from './ng-chat/core/chat-adapter';
import { SocketIOAdapter } from './socketio-adapter';
*/
import { Idle, EventTargetInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { Subscription, Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';
import { BrowserService } from './@core/services/browser.service';
import { HttpService } from './@core/services/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'DMI Platform';
  user: any;

  //Chat
  // Commented > to stop polling chat
  // public adapter: SocketIOAdapter;
  userId: string;
  username: string;
  private userData: any;

  showChat = false;


  idleState = 'NOT_STARTED';
  timedOut = false;
  lastPing?: Date = null;
  _idleTimerSubscription: Subscription;
  private watch: Observable<any>;
  subscription: Subscription;
  private timer: Observable<any>;
  public timeoutExpired: Subject<number> = new Subject<number>();
  isSessionNeedsTobeInLife = false;
  @ViewChild('sessiontimeout') sessiontimeoutpopup: TemplateRef<any>;
  private closeDialog: MatDialogRef<TemplateRef<any>>;
  showLoader: any = [];
  isSpinnerShow: boolean;
  constructor(private browserstorage: BrowserService,
    private localstorage: LocalService, private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public appSettings:AppSettings,
    // Commented > to stop polling chat
    // private socket: Socket,
    private http: Http, private service: CommonHttpService,
    private idle: Idle, private keepalive: Keepalive,
    private zone: NgZone, private element: ElementRef,public dialog: MatDialog,
    private httpService: HttpService
  ) {
    this.zone.runOutsideAngular(() => {
      // sets an idle timeout of 10 minutes.
      idle.setIdle(environment.IdleTimeOut);
      // sets a timeout period of 5 minutes.
      idle.setTimeout(environment.PopupTimeOut);
      // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
      idle.setInterrupts([
        new EventTargetInterruptSource(
          this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown scroll')]);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'NO_LONGER_IDLE';
        console.log(this.idleState);
        this.continueSession();
      });

      idle.onTimeout.subscribe(() => {
        this.idleState = 'TIMED_OUT';
        console.log(this.idleState);
        this.closeDialogBox();
        this.timedOut = true;

      });

      idle.onIdleStart.subscribe(() => {
         this.checkIfSessionExist();
      });

      idle.onTimeoutWarning.subscribe((countdown: any) => {
        console.log('Minutes', (Math.floor(countdown / 60)), 'countSeconds', countdown % 60);
        const sec = countdown % 60 < 10 ? '0' + countdown % 60 : countdown % 60;
        document.getElementById('time').innerHTML = (Math.floor(countdown / 60)) + ':' + sec;
      });

      // sets the ping interval to 15 seconds
      keepalive.interval(15);

      keepalive.onPing.subscribe(() => {
        this.lastPing = new Date();
        // console.log('keepalive.onPing>> ' + this.lastPing);
      });
      this.reset();
    });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  closeDialogBox() {
    this.closeDialog ? this.closeDialog.close() :'';
    this.auth.logout();
  }

  continueSession() {
    //this.auth.callAPIToResetCokiesBackFromOpenAm();
    this.reset();
    document.getElementById('time').innerHTML = null;
    this.closeDialog ? this.closeDialog.close() :'';
  }
  logOutSession() {
    this.closeDialog ? this.closeDialog.close() :'';
    this.auth.logout();

  }

  checkIfSessionExist() {
    this.user = this.localstorage.getObj('user');
    if (this.user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '200px';
    dialogConfig.width = '370px';
    dialogConfig.disableClose = true;
    this.closeDialog = this.dialog.open(this.sessiontimeoutpopup, dialogConfig);
    }

   }

  ngOnInit() {
    this.user = this.localstorage.getObj('user');

    this.auth.chatEnabled.subscribe(data => {
      console.log("app show chat");
      this.showChat = true;
      console.log("showing chat");
      // Commented > to stop polling chat
      // this.InitializeSocketListerners();
    });

    // this.InitializeSocketListerners();
    if (this.user) {
      this.auth.setAuth();
      // this.InitializeSocketListerners();
      if (window.location.hash === '#/login') {
        this.router.navigate(['/pages/view-dashboard']);
      }
    } else if (this.browserstorage.getObj('token')) {
      this.auth.setUserFromToken(this.browserstorage.getObj('token')).subscribe(result => {
        this.localstorage.setObj('token', this.browserstorage.getObj('token'));
        this.auth.setAuth();
      },
      (error) => {
        console.log(error);
        this.auth.logout();
      });
    } else {
      // console.log(window.location.hash)
      // console.log(this.router.url);
      // Temporary purpose implemented the autologin for fleet management
      if (window.location.hash === '#/autologin') {
        this.router.navigate(['/autologin']);
      }
       else if (window.location.hash != '#/login'){
        this.auth.logout();
      }
    }


		this.httpService.outsideShowSpinner.subscribe((data: boolean) => {
			// tslint:disable-next-line:indent
      console.log("---",data)
			if (data) {
				this.showLoader.push(data);
				setTimeout(() => {
					this.isSpinnerShow = data;
				});
			} else {
				this.showLoader.pop();
			}
			if (this.showLoader.length === 0) {
				setTimeout(() => {
					this.isSpinnerShow = data;
				});
			}

		});
  }

  // Commented > to stop polling chat
  /* public InitializeSocketListerners(): void
  {
    this.user = this.localstorage.getObj('user');
    this.socket.emit("join", this.user);
    this.userId = this.user.id;
    this.adapter = new SocketIOAdapter(this.userId, this.socket, this.http, this.service);

    // this.socket.on("generatedUserId", (userId: string) => {
    //         // Initializing the chat with the userId and the adapter with the socket instance
    //   this.adapter = new SocketIOAdapter(userId, this.socket, this.http, this.service);
    //   this.userId = userId;
    // });
  } */
} 
