import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from 'src/app/@core/services/local.service';
declare var videojs: any;
import * as es6printJS from "print-js";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit,AfterViewInit {

  documentType = '';
  docid = '';
  documentURL = ''
  private videoJSplayer: any;
  public videoJsConfigObj = {
    preload: "metadata",
    controls: true,
    autoplay: true,
    overrideNative: true,
    techOrder: ["html5"],
    html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        hls: {
            withCredentials: false,
            overrideNative: true,
            debug: true
        }
    }
};

  constructor(private route: ActivatedRoute,private localstore: LocalService,private titleService:Title) { 
    
    this.docid = this.route.snapshot.paramMap.get('docid');
    const docObject = this.localstore.getObj(this.docid);
    this.documentType = docObject.type;
    this.documentURL = docObject.url;
    const claimNumber = docObject.claim_number;
    const title = `${claimNumber} ${docObject.name}`
    this.titleService.setTitle(title);
  }

  ngOnInit(): void {
    // var player = videojs('my-video', this.videoJsConfigObj);
   
  }
  ngAfterViewInit() {
    if(this.documentType == 'video') {
      this.videoJSplayer = videojs(document.getElementById('my-video'), this.videoJsConfigObj);
    }
    
  }

  save() {
    window.open(this.documentURL, '_blank');
  }

  print() {
    es6printJS({ printable: this.documentURL, showModal: true });
  }

  printImage() {
    es6printJS("image-container","html")
  }
}
