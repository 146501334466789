// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.tesla.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //apiHost: 'http://ec2-34-197-99-207.compute-1.amazonaws.com:port/api',
    baseUrl: 'https://api.qa.subromanagement.com/',
    apiHost: 'port',
    sso_api_username:'my-client',
    sso_api_pwd:'my-secret',
    chatServer: 'https://dev-api.casecloudai.com/chat/',
    visualization: 'http://dev-elastic.casecloudai.com:5601/s/dmi-platform-web/app/dashboards#/list?_g=(filters:!(),refreshInterval:(pause:!f,value:900000),time:(from:now-7d,to:now))',
    devTool: "http://dev-elastic.casecloudai.com:5601/app/dev_tools#/console?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:900000),time:(from:now-24h,to:now))&_a=(description:'Analyze%20mock%20flight%20data%20for%20ES-Air,%20Logstash%20Airways,%20Kibana%20Airlines%20and%20JetBeats',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!t,title:'%5BFlights%5D%20Global%20Flight%20Dashboard',viewMode:view)",
    analytics:'https://app.powerbi.com/reportEmbed?reportId=7174deeb-c4a5-4433-8534-52cea1fb053b&groupId=79947807-d42c-4d66-ab17-af9256c8c4fd',
    embedReportId:'79947807-d42c-4d66-ab17-af9256c8c4fd',
    kibanaUrl: 'http://dev-elastic.casecloudai.com:5601/app/kibana',
    grafanaUrl: 'https://dev-grafana.casecloudai.com:3000/',
    version: 'v1.0',
    assetDashboard:'https://d3sw86b8uaycur.cloudfront.net/dashboard',
    WBUSalesDashboard:'http://35.171.110.175:3000/public/dashboard/2559d284-13c0-4b4c-b015-9268226deab9',
    harperdb: 'https://dev-harperdb.casecloudai.com:9925/',
    confluent: 'http://ec2-52-70-56-59.compute-1.amazonaws.com:9021/',

    // Socketio
    socket_endpoint : "a2xoug9pmb42ez-ats.iot.us-east-1.amazonaws.com",
    socket_region : 'us-east-1',
    socket_access_key : 'AKIA5CYAD3B4XX36SB5E',
    socket_secret_key : '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',
    IdleTimeOut: 14400,
    PopupTimeOut: 300,
    s3BucketUrl: 'https://dmi-platform-demo.s3.amazonaws.com',
    tempUsername: "admin@dmi.com",
    tempPassword: "Password@123",
    teslaBaseUrl : 'https://qcan6nu7si.execute-api.us-west-2.amazonaws.com/tesla-qa/',
    coginotoConifg:{
      "aws_project_region": "us-east-1",
      "Auth": {
         
          "region": "us-west-2",
          "userPoolId": "us-west-2_vjtHl7iQ0",
          "userPoolWebClientId": "72nqfefmp5qveo0msachkqu2sh"
      }
  }
  };
