<div id="overlay" style="display:block;" *ngIf="isSpinnerShow">
  <div class="spinner"></div>
  <br />
  Loading....
</div>
<ng-chat *ngIf="showChat" [adapter]="adapter" [groupAdapter]="adapter" [userId]="userId" [historyEnabled]="false" [theme]="'light-theme'"></ng-chat>
<router-outlet></router-outlet>
<ng-template #sessiontimeout>

    <div >
        <div class="modal-content session-timeout">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h4 class="timeout-title">Session Timeout</h4>
                    </div>
                </div>
                <div class="row">
                     <div class="col-md-12 text-center">
                          <div>  Your Session Expired  <br>
                              <!-- <i style="font-size: 25px;color: blue" class="fa fa-clock-o fa-spin"></i>
                               <div id="time" style="font-size: 25px;color: green"></div>If you want to continue, Please click "Stay Logged in". -->
                          </div>

                     </div>
                </div>
                <div class="row">
                    <br>
                    <div class="col-md-12 text-center">
                            <div class="btn-group btn-group-re">
                                <button type="button" style="margin-right: 20px" class="btn-pri pull-left"
                                (click)="logOutSession()">Log Out</button>
                                <!-- <button type="button" class="btn-pri pull-right"
                                (click)="continueSession()">Stay Logged in</button> -->
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>


</ng-template>
