import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from 'src/app/@core/services/alert.services';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-tempsignup',
  templateUrl: './tempsignup.component.html',
  styles: []
})
export class TempsignupComponent implements OnInit {

  loginForm: FormGroup;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  show: boolean;
  passwordShow: boolean;
  otp:boolean;
  @ViewChild('showhideinput') input;
  viewOption: string;

  constructor(private auth: AuthService,private alert: AlertService,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('' , [Validators.required]),
      password: new FormControl('' , [Validators.required, Validators.minLength(8)]),
      aggree: new FormControl(true, Validators.required),
      otp: new FormControl(''),
      mfa: new FormControl(''),
      repassword:new FormControl('' ),
      displayname:new FormControl('' , [Validators.required])
    });
    this.passwordShow = true;
    // This is not good practice just doing for demo
    this.route.paramMap.subscribe((params : ParamMap)=> { 
      if(params.get('userid')=== '9332dc94-1ac9-4a46-9750-0c65af28ce1a' ) {
        this.loginForm.patchValue({user : "aguru@dminc.com",password: "Password@123"})
        this.auth.login(this.loginForm.value); 
      }
      if(params.get('userid')=== '060ecf49-6a7f-46b2-b99a-78d71a06d763' ) {
        this.loginForm.patchValue({user : "dpatra@dminc.com",password: "Password@123"})
        this.auth.login(this.loginForm.value); 
      }
      if(params.get('userid')=== 'ba35633f-8512-4674-bf84-30f3abae6876' ) {
        this.loginForm.patchValue({user : "mradhakrishnan@dminc.com",password: "Password@123"})
        this.auth.login(this.loginForm.value); 
      }   
     }); 
  }

  singup() {
    if (this.loginForm.valid) {
      this.auth.signUP(this.loginForm.value);
    }
  }

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
        this.input.nativeElement.type = 'text';
    } else {
        this.input.nativeElement.type = 'password';
    }
  }

  sendCode(){
    this.otp=true;
    this.loginForm.patchValue({aggree:true});
    this.auth.sendCode(this.loginForm.value);
  }

 

  confirmPassword(){
    
    const password = this.loginForm.getRawValue().password;
    const repassword = this.loginForm.getRawValue().repassword;
    if(password===repassword){
       this.passwordShow = true;
       this.otp=false;
       this.auth.confirmPassword(this.loginForm.value);
    } else {
       this.alert.error('Password does not match.')
    }
    
  }

  forgotPassword(isP){
    this.loginForm.reset();
    this.passwordShow = isP;
    this.otp=false;
  }

}
