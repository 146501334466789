import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
@Injectable({
  providedIn: 'root'
})
export class BrowserService extends StorageService {
  constructor() {
    super(window.localStorage);
  }
}
