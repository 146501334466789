export class CommonUrlConfig {
    public static EndPoint = {
        intake: {
            GetNextNumberUrl: 'uniqueIdLogic/IntakeNumber',
            cjamspid: 'uniqueIdLogic/persondpid',
            menu: 'asset/list/',
            tabconfig: 'tabconfig/?type=Intake&purpose=',
            saveintake: 'intake/',
            getUrl: 'intake',
            tabconfigList: 'tabconfig/',
            createCase: 'cases/',
            getProviderInquiryId:'uniqueIdLogic/ProviderInquiry',
            createInquiry: 'intake/inquiry/'
        },
        dropDown: {
            Suffix: 'referencetype/Suffix',
            Gender: 'referencetype/Gender',
            Race: 'referencetype/Race',
            Ethnicity: 'referencetype/Ethnicity',
            HairColor: 'referencetype/HairColor',
            EyeColor: 'referencetype/EyeColor',
            AddressType: 'referencetype/AddressType',
            State: 'referencetype/State',
            County: 'referencetype/County',
            Status: 'referencetype/Status',
            PhoneType: 'referencetype/PhoneType',
            EmailType: 'referencetype/EmailType',
            Agency: 'referencetype/Agency',
            Communication: 'referencetype/Communication',
            Purpose: 'referencetype/Purpose',
            PersonRoles: 'referencetype/PersonRoles',
            PersonType: 'referencetype/PersonType',
            Relationships: 'referencetype/Relationships',
            DocumentCategory: 'referencetype/DocumentCategory',
            DocumentSubCategory: 'referencetype/DcoumentSubCategory',
            DocumentCategoryPublicProvider: 'referencetype/DocumentCategoryPublicProvider',
            DocumentSubCategoryPublicProvider: 'referencetype/DocumentSubCategoryPublicProvider',
            DocumentCategoryTrainingSession: 'referencetype/DocumentCategoryTrainingSession',
            DocumentSubCategoryTrainingSession: 'referencetype/DocumentSubCategoryTrainingSession',
            DocumentCategorysafeHomeStudy: 'referencetype/DocumentCategorysafeHomeStudy',
            DocumentSubCategorySafeHomeStudy: 'referencetype/SafeHomeStudyCategory',
            DocumentCategoryPrivateProvider: 'referencetype/DocumentCategoryPrivateProvider',
            DocumentSubCategoryPrivateProvider: 'referencetype/DocumentSubCategoryPrivateProvider',
            DocumentType: 'referencetype/DocumentType',
            module: 'referencetype/Module',
            page: 'referencetype/page',
            ReporterRole: 'referencetype/ReporterRole',
            ReporterType: 'referencetype/ReporterType',
            ResponseType: 'referencetype/ResponseType',
            Decision: 'referencetype/Decision',
            status: 'referencetype/Status',
            workflowtype: 'referencetype/workflowtype',
            logicalOperators: 'referencetype/LogicalOperators',
            arithmeticOperators: 'referencetype/ArithmeticOperators',
            LoginWith: 'referencetype/LoginWith',
            gridIcon: 'referencetype/GridIcon',
            gridDateFormat: 'referencetype/GridDateFormat',
            Module: 'referencetype/Module',
            SubModule: 'referencetype/SubModule',
            docTypes: 'referencetype/Document Type Extensions',
            AppointmentOutcomeType: 'referencetype/AppointmentOutcomeType',
            AppointmentMode: 'referencetype/AppointmentMode',
            AppointmentTitle: 'referencetype/AppointmentTitle',
            AppointmentSubMode: 'referencetype/AppointmentSubMode',
            AppointmentDurationHours: 'referencetype/AppointmentDurationHours',
            AppointmentDurationMinutes: 'referencetype/AppointmentDurationMinutes',
            PageMergeType: 'referencetype/PageMergeType',
            PageMergeMode: 'referencetype/PageMergeMode',
            LivingSituation: 'referencetype/LivingSituation',
            Religion: 'referencetype/Religion',
            Langauge: 'referencetype/Langauge',
            PrimaryCitizenship: 'referencetype/PrimaryCitizenship',
            Glasses: 'referencetype/Glasses',
            SkinTone: 'referencetype/SkinTone',
            PhysicalBuild: 'referencetype/PhysicalBuild',
            AlienStatus: 'referencetype/AlienStatus',
            MaritalStatus: 'referencetype/MaritalStatus',
            Prefix: 'referencetype/Prefix',
            AKAType: 'referencetype/AKAType',
            PersonSubstanceExposed: 'referencetype/PersonSubstanceExposed',
            Database: 'referencetype/Database',
            Technology: 'referencetype/Technology',
            TechnologyMethod: 'referencetype/TechnologyMethod',
            Calculation: 'referencetype/Calculation',
            APIType: 'referencetype/APIType',
            HTTPMethod: 'referencetype/HTTPMethod',
            ResponseCode: 'referencetype/ResponseCode',
            APIMode: 'referencetype/APIMode',
            Rules: 'referencetype/Rules',
            ComponentType: 'referencetype/ComponentType',
            DatabaseName: 'referencetype/DB',
            CallingState: 'referencetype/CallingState',
            DGPJsonMode: 'referencetype/DGPJsonMode'
        },
        narrative: {
            addUpdateUrl: 'incident/',
            getAllUrl: 'incident/listByIntakeIdOrCaseId',
            getUrl: 'incident',
            deleteUrl: 'incident',
            getBySourceId: 'incident/listBySourceId/'
        },
        decision: {
            addUpdateUrl: 'decision/',
            getUrl: 'decision/listBySourceid',
            pageData: 'pagedata/getBySourceid',
        },
        person: {
            addUpdateUrl: 'person/',
            getUrl: 'person',
            connector: 'connector/',
            getconnector: 'connector',
            search: 'person/search',
            upload: 'document/upload'
        },
        alias: {
            addUpdateUrl: 'personAlias/',
            deleteUrl: 'personAlias'
        },
        maritalInfo: {
            addUpdateUrl: 'personMaritalInfo/'
        },
        relation: {
            addUpdateUrl: 'personRelation/',
            getUrl: 'personRelation',
        },
        address: {
            addUpdateUrl: 'personAddress/',
            deleteUrl: 'personAddress',
            getListUrl: 'personAddress'
        },
        contact: {
            phone: {
                addUpdateUrl: 'personPhone/',
                getListUrl: 'personPhone',
                deleteUrl: 'personPhone',
            },
            email: {
                addUrl: 'personEmail/save',
                UpdateUrl: 'personEmail/update',
                getListUrl: 'personEmail',
                deleteUrl: 'personEmail'
            },
        },
        formBuilder: {
            assesmenttemplate: 'assesmenttemplate/',
            get: 'assesmenttemplate'
        },
        assessment: {
            assesment: 'assesment/',
            get: 'assesment',
            templateId: 'assesment/template'
        },
        auth: {
            login: 'oauth/token',
            user: 'user',
            appLogin:'login',
            appForgotPassword:'forgot-password',
            confirmForgotPassword:'confirm-forgot-password',
            changePassword: 'change-password'
        },
        personInvolved: {
            list: 'connector'
        },
        document: {
            list: 'document/listBySourceId/',
            find: 'document',
            delete: 'document',
            uploadFile: 'document/upload',
            save: 'document/',
            gendoclist: 'documentgenerate/',
            generatedoc: 'documentgenerate/pdf/',
            generatePDF: 'document/exportPdf',
            generateExcel: 'document/exportExcel'
        },
        refKey: {
            url: 'refKey/'
        },
        refKeyValue: {
            url: 'refKeyValue/',
            actions: 'referencetype/action'
        },
        tabconfig: {
            url: "tabconfig/"
        },
        workflow: {
            page: "page",
            basic: "basic",
            pageByOrganization: "page/organisation",
            decisonSubmit: 'workflow/',
            workflowDesign: 'workflowdesigncontroller/',
            workflowDesignget: 'workflowdesigncontroller/{id}',
            workflow: 'workflow/initworkflow',
            workflowrecord: 'workflow/{id}',
            workflowAudit: 'workflow/getAudit/{id}',
            databaseSchema: 'database/',
            pagedatabyPageid: 'pagedata/getPageDataList'
        },
        report: {
          report: "report",
          schedulertrigger: "schedulerreport/trigger",
          adhocreport: 'report/adhoc/'
        },
        apiFlow: {
            apidesigner: 'apiflowcontroller/',
            getapidesigner: 'apiflowcontroller/{id}',
            kafkaTopicapi: 'dynamic-api/topics'
        },
        notification: {
          get: 'notification/{id}',
          notificaitonList:'notification/',
          create: 'notificationconfig/',
          templateList:'notificationconfig/All',
          getTemplate:'notificationconfig/{id}',
          subscribition:'usersubscription/',
          subcribitionList:'usersubscription/All',
          post:'notification/{id}',
          sendMail: 'communication/sendmail',
          usersurvey: 'surveyconfig/usersurvey/{id}/{status}/{pagedataid}'
        },
        config: {
            database: "database"
        },
        rbac: {
            role: "role",
            usertheme: {
                list: "theme/{orgId}",
                save: "theme/"
            },
            organization: {
                list: 'organization/',
                addUpdateUrl: 'organization/',
                deleteUrl: 'organization'
            },
            locations: {
                list: 'locations/{orgId}',
                addUpdateUrl: 'locations/',
                deleteUrl: 'locations'
            },

            office: {
                list: 'office/{orgId}',
                addUpdateUrl: 'office/',
                deleteUrl: 'office',
                listbylocid: 'office/getBylocId'
            },
            bunit: {
                list: 'businessunit/all',
                addUpdateUrl: 'businessunit/',
                deleteUrl: 'businessunit',
                getbyorgid: 'businessunit/getByOrgId'
            },
            policygrp: {
                list: 'policyGroup/all',
                addUpdateUrl: 'policyGroup/',
                deleteUrl: 'policyGroup'
            },
            addrole: {
                list: 'role/all',
                listById: 'role/',
                addUpdateUrl: 'role/',
                deleteUrl: 'role'
            },
            dept: {
                list: 'department/all',
                addUpdateUrl: 'department/',
                deleteUrl: 'department',
                listbybunitid: 'department/getBybusinessunitId'
            },
            team: {
                list: 'team/all',
                addUpdateUrl: 'team/',
                deleteUrl: 'team',
                listbydeptid: 'team/getByDeptId'
            },
            users: {
                list: 'users/all',
                addUpdateUrl: 'users/',
                deleteUrl: 'users',
                userListByRole: 'users/listUserByRole?rolekey='
            },
            policyRoleForm: {
                addUpdateUrl: 'usersrolepolicy/',
                list: 'usersrolepolicy/getByUserId'
            },
            workInfo: {
                addUpdateUrl: 'userworkinfo/',
                list: 'userworkinfo/getByUserId'
            },
            rbacpage: {
                getpagelistbymodule: 'pagesubmodconfig/getByModulekey/',
                getpagelistbysubmodule: 'pagesubmodconfig/getBySubmodulekey/',
            },
            asset: {
                saveAsset: 'asset/',
                list: 'asset/getByAssetType/',
                batch: 'asset/batch/',
                listByPageID: 'asset/getByPageId'
            },
            pagemapping: {
                list: 'pagesubmodconfig/all',
                get: 'pagesubmodconfig',
                addUpdateUrl: 'pagesubmodconfig/',
                deleteUrl: 'pagesubmodconfig',
            },
        },
        appointment: {
            list: "appointment/",
            delete: "appointment",
            listById: "appointment",
            listBySourceId: 'appointment/listBySourceId',
            listByParticipantId: 'appointment/listByParticipantId'
        },
        mergepage: {
            list: "moduleribbon/getAll",
            addUpdateUrl: "moduleribbon/",
            get: 'moduleribbon',
            deleteUrl: 'moduleribbon',
            getDetails: 'moduleribbon/getModuleDetails/'
        },
        "pagemerge": {
            list: "pagemerge/getAll",
            addUpdateUrl: "pagemerge/",
            get: 'pagemerge',
            deleteUrl: 'pagemerge'
        },
        checklist: {
            isCheckList: "checklisttaskconfig/isChecklist",
            checkListAutoComplete: "checklist/autocomplete",
            checkListStatus: "checklist/",
            checklistprogess: "checklist"
        },
        ribbon: {
            ribbon: "cases/detailsByCaseId",
            caseDetails: "cases/getCaseDetailsByIntake",
            finance: "clientDetail/ribbon",
            finanaceList: "person",
            provider: "provider",
            commonaccount: "commonaccount"
        },
        finance: {
            GetNextNumberUrl: 'uniqueIdLogic/',
            saveTransactionUploadUrl: 'transactionFile/',
            updateInvoiceStatus: 'invoice/updatestatus/',
            updateField: 'invoice/updateField/',
            updateFieldByIds: 'invoice/updateFieldByIds/',
            notice: 'notices/',
            reportDownload: 'integrated/exportDocuments/'
        },
        harperdb: {
            harperdb:'api/dynamicapi/',
            createInquiry:'integrated/createinquiry/',
            createIncident: 'integrated/createincident/'
        }
    };
};
