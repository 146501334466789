import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/@core/services/alert.services';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { DynamicSearchComponent } from 'src/app/dynamic-search/dynamic-search/dynamic-search.component';
import { SearchListComponent } from '../search-list/search-list.component';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {

  public isActive: any ;
  claimID = '';
  constructor(private commonHttpService: CommonHttpService,
    private router: Router,
    private alert:AlertService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    this.isActive = true;
  }

  searchClaim() {
    this.isActive = true;
    console.log("search for claim",this.claimID);
    const claimid = this.claimID.trim();
    if(claimid == '') {
      this.alert.error('Please enter Claim/OIC Claim ID. ');
      return;
    }
    this.commonHttpService.create({'claim_id':claimid},`tesla/api/v1/claim/search`).subscribe(response => {
      console.log(response);
      if(response.length == 1) {
        this.close();
        this.router.navigateByUrl('/pages', {skipLocationChange: true}).then(()=>
          
        this.router.navigate([response[0].link_url]));
      } else if (response.length >1){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.height = '90%';
        dialogConfig.width = '80%';
    
    
        dialogConfig.data = { response };
        const ref = this.dialog.open(SearchListComponent, dialogConfig);
      } else {
        this.alert.error(this.claimID + ' Claim/OIC Claim ID not found. ');
        this.close();
      }
    })
  }

  close () {
    this.isActive = false;
    this.claimID = "";
  }
}
