import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from 'src/app/@core/services/alert.services';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  supportEmail = 'support.subrogation@dminc.com';
  loginForm: FormGroup;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  show: boolean;
  passwordShow: boolean;
  otp:boolean;
  @ViewChild('showhideinput') input;
  viewOption: string;

  constructor(private auth: AuthService,private alert: AlertService,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      user: new FormControl('' , [Validators.required]),
      password: new FormControl('' , [Validators.required, Validators.minLength(8)]),
      aggree: new FormControl(true, Validators.required),
      otp: new FormControl(''),
      mfa: new FormControl(''),
      repassword:new FormControl('' )
    });
    this.passwordShow = true;
    this.authListen();
  
  }

  login() {
    if (this.loginForm.valid) {
      this.auth.appLogin(this.loginForm.value);
    }
  }

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
        this.input.nativeElement.type = 'text';
    } else {
        this.input.nativeElement.type = 'password';
    }
  }

  sendCode(){
    this.otp=true;
    this.loginForm.patchValue({aggree:true});
    this.auth.appForgotPassword(this.loginForm.value);
  }

 

  confirmPassword(){
    
    const password = this.loginForm.getRawValue().password;
    const repassword = this.loginForm.getRawValue().repassword;
    const otp =  this.loginForm.getRawValue().otp;

    if(password != repassword){
      this.alert.error('Password does not match.');
      return false;
    }
    if(otp && otp.trim() == '') {
      this.alert.error('Password enter otp');
      return false;
    }
    this.auth.appForgotConfirmPassword(this.loginForm.value);
    
    
    
  }

  forgotPassword(isP){
    this.loginForm.reset();
    this.loginForm.patchValue({aggree:true});
    this.passwordShow = isP;
    this.otp=false;
  }

  authListen() {
    this.auth.authListener.subscribe(data => {
      if(data = 'PASSWORD-UPDATED') {
        this.passwordShow = true;
        this.otp=false;
      }
    });
  }

}
