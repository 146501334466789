<div class="h-100" *ngIf="false">
  <div class="row h-100 no-gutters">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem>
            <div class="position-relative h-100 d-flex justify-content-center align-items-center"> <!-- bg-plum-plate -->
              <div class="slide-img-bg"><img src="../../../../assets/images/login/Slider_01.jpg"/></div>
              <div class="slider-content text-light">
                <div class="slider-cont-wrp">
                  <!-- <h3>Claim Management</h3>
                   -->
                </div>
              </div>
            </div>
          </div>
         
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="app-logo"><img src="../../../../assets/images/dmi_full_logo.png"/></div>
        <h4 class="mb-4">
          <div class="">Claim Management</div>
        </h4>
        <!-- <h6 class="mt-3">
          No account?
          <a [routerLink]="" class="text-primary">Sign up now</a></h6> -->
        <div class="divider"></div>
        <div>
          <form [formGroup]="loginForm">
            <div class="row">
              <div class="col-md-6">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group"><label for="exampleEmail">Email</label><input id="exampleEmail"
                    formControlName="user" type="text" placeholder="Email here..." class="form-control">
                  </div>
                </fieldset>
              </div>
              <div class="col-md-6" *ngIf="passwordShow || otp">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group"><label for="examplePassword">Password</label>
                    <input id="examplePassword" formControlName="password" type="password" placeholder="Password here..."
                      class="form-control" #showhideinput>
                      <i toggle="#password" class="fa fa-fw fa-eye position-ab" (click)="toggleShow()"></i>
                  </div>
                </fieldset>
              </div>
              <div class="col-md-6" *ngIf="otp">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group"><label for="examplePassword">Password</label>
                    <input id="examplePassword" formControlName="repassword" type="password" placeholder="Password here..."
                      class="form-control" #showhideinput>
                      <i toggle="#password" class="fa fa-fw fa-eye position-ab" (click)="toggleShow()"></i>
                  </div>
                </fieldset>
              </div>
               <div class="col-md-6" *ngIf="otp">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group"><label for="OTP">OTP</label>
                    <input id="OTP" formControlName="otp" type="text" placeholder="OTP..."
                      class="form-control" #showhideinput>
                      
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- <div class="custom-control custom-checkbox" *ngIf="passwordShow"><input id="exampleCheck" type="checkbox" formControlName="aggree" 
                autocomplete="off" class="custom-control-input"><label for="exampleCheck"
                class="custom-control-label">
                I agree to the terms & conditions of DHHS
              </label></div> -->
             
            <div class="divider"></div>
            <div class="d-flex align-items-center">
              <div class="mr-auto d-inline-block">
                <p class="m-0">Powered by
                  <a href="https://dminc.com/"><img src="./assets/images/dmi_logo.png"
                          class="dmi-logo" />Digital Management, LLC</a>
                  </p>
                  <p class="m-0">Contact Support: 
                    <a href="https://dminc.com/"><img src="./assets/images/contact-support.png"
                            class="dmi-logo" />support.subrogation@dminc.com</a>
                    </p>
              </div>
              <div class="ml-auto d-inline-block">
                <a *ngIf="passwordShow" (click)="forgotPassword(false)" class="btn btn-link">Forget
                  Password?
                </a>
                <a *ngIf="!passwordShow && !otp" (click)="forgotPassword(true)" class="btn-lg btn btn-link">Back to login  </a>
                <a *ngIf="otp" (click)="sendCode()" class="btn-lg btn btn-link">Resend Code ?  </a>
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme" [disabled]="loginForm.invalid" (click)="login()" *ngIf="passwordShow">Login</button>
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme"  (click)="sendCode()" *ngIf="!passwordShow && !otp">Send Code</button>
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme"  (click)="confirmPassword()" *ngIf="otp">Update Password</button>
              </div>
            </div>
          </form>
        </div>
        <!-- <div class="login-footer">
          <p>Powered by
              <a href="https://dminc.com/"><img src="./../../../../assets/images/dmi_logo.png"
                      class="dmi-logo" />Digital Management, LLC</a>
              </p>
        </div> -->
      </div>
    </div>
  </div>
</div>


<div class="full-row login-wrapper">
  <div class="table height-vh-full">
      <div class="table-cell height-vh-full" style="padding: 0 40px;">
          <img src="./../../../../assets/images/login-side-img.png" style="max-width: 100%;" alt="">
          <div class="full-row margin-top-20">
              <span class="red-color font-size-40 proxima-nova-condensed-light">SUBROGATION</span>
              <span class="font-size-40 proxima-nova-condensed-light"> MANAGEMENT</span>
          </div>
      </div>
      <div class="table-cell height-vh-full grey-gradient">
          <section class="login-form">
            <div class="table-cell">
            <form [formGroup]="loginForm" >
              <div class="full-row text-center">
                  <img src="./../../../../assets/images/dmi-logo-text.png" class="margin-top-10" style="max-width:135px" alt="">
              </div>
              <div class="full-row margin-bottom-20 margin-top-15">
                <input id="exampleEmail"
                formControlName="user" type="text" autocomplete="off" placeholder="Email here..." class="form-control">
              </div>
              <div class="full-row margin-bottom-15 relative" *ngIf="passwordShow || otp">
                      <input id="examplePassword" autocomplete="off" formControlName="password" type="password" placeholder="Password here..."
                        class="form-control" #showhideinput>
                        <i toggle="#password" class="fa fa-fw fa-eye position-ab" (click)="toggleShow()" style="color:#CE0000;position: absolute;top:12px;right: 10px;"></i>
              </div>
              <div class="full-row margin-bottom-15 relative" *ngIf="otp">
                <input id="examplePassword" autocomplete="off" formControlName="repassword" type="password" placeholder="Password here..."
                      class="form-control" #showhideinput>
                      <i toggle="#password" class="fa fa-fw fa-eye position-ab" (click)="toggleShow()" style="color:#CE0000;position: absolute;top:12px;right: 10px;"></i>
              </div>
              <div class="full-row margin-bottom-15 relative" *ngIf="otp">
               
                  
                    <input id="OTP"  autocomplete="off" formControlName="otp" type="text" placeholder="OTP..."
                      class="form-control" >
                 
              </div>
              <div class="full-row margin-bottom-15 margin-top-5 relative">
                  <!-- <span class="float-left font-size-12 black-3D3D3D">
                      <i class="fa fa-square-o font-size-14 red-color" style="position: relative; top:1px;margin-right: 3px;" aria-hidden="true"></i>
                      Remember Me
                  </span> -->
                  <a class="decoration-none font-size-12 black-3D3D3D" *ngIf="otp" (click)="sendCode()" >Resend Code ?  </a>
                  <a class="float-right decoration-none font-size-12 black-3D3D3D" *ngIf="passwordShow" (click)="forgotPassword(false)">Forgot Password?</a>
                  <a class="float-right decoration-none font-size-12 black-3D3D3D" *ngIf="!passwordShow" (click)="forgotPassword(true)" >Back to Login  </a>
              </div>
              <div class="full-row margin-bottom-10 relative">
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme" [disabled]="loginForm.invalid" (click)="login()" *ngIf="passwordShow">Login</button>
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme"  (click)="sendCode()" *ngIf="!passwordShow && !otp">Send Code</button>
                <button type="button" class="btn btn-primary btn-lg btn-custom-theme"  (click)="confirmPassword()" *ngIf="otp">Update Password</button>
              </div>
            </form>
              <hr class="margin-top-10 hr-ccc">
              <div class="full-row margin-bottom-10 margin-top-0 text-center">
                  
                  <img src="./assets/images/contact-support.png" class="margin-right-5" style="max-width:14px;position: relative;top:0;right: -0;" alt="">
                  <span class="font-size-14 blue-2050C2">For login or technical issues, contact <a href = "mailto: {{supportEmail}}">{{supportEmail}}</a></span>
              </div>
              <div class="full-row margin-bottom-10 margin-top-0 text-center">
                <span class="font-size-12 black-3D3D3D margin-right-5">Powered by</span>
                <img src="./assets/images/dmi_logo.png" class="margin-right-5" style="max-width:14px;position: relative;top:0;right: -0;" alt="">
                <span class="font-size-12 blue-2050C2">Digital Management. LLC</span>
            </div>

              <figure></figure>
              <div class="white-grey-gradient"></div>
            </div>
          </section>
      </div>
  </div>
</div>