import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppSettings } from 'src/app/app.settings';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { LocalService } from 'src/app/@core/services/local.service';
import { HttpClient } from '@angular/common/http';
import { DataStoreService } from 'src/app/@core/services/data-store.services';
// import { PagesComponent } from 'src/app/pages/pages.component'
import { environment } from 'src/environments/environment';
import { Settings } from 'src/app/app.settings.model';
import { CommonUrlConfig } from 'src/app/@core/common/URLS/common-url.config';
import { PortConfig } from 'src/app/@core/common/URLS/port.config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  // public userImage = '../../../../assets/images/deafult-pic.jpg';
  public userImage = '';
  public settings: Settings;
  user: any;
  menuList: any;
  version: any;

  constructor(public globals: ThemeOptions, private activatedRoute: ActivatedRoute,
    public appSettings: AppSettings,
    // public PagesComponent: PagesComponent,
    public menuService: MenuService,
    private commonHttpService: CommonHttpService,
    private localstorage: LocalService,
    private http: HttpClient,
    private dataStore:DataStoreService,
    private router: Router) {
      this.version = environment.version;
      this.settings = this.appSettings.settings;
  }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1025) {
        this.globals.toggleSidebar = true;
      } else if (this.innerWidth > 1200) {
        this.globals.toggleSidebar = true;
      } else {
        this.globals.toggleSidebar = false;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;

    this.user = this.localstorage.getObj('user');
    if (this.user) {
      if(this.user.photoUrl)
      {
        this.userImage = environment.s3BucketUrl + '/' + this.user.photoUrl
      }
      else
      {
        this.userImage='../../../assets/images/deafult-pic.jpg'
      }
      this.loadMenu();
    } else {
      this.loadUserData();
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

  loadMenu() {
    const userID = this.user ? this.user.id : '';
    const roleId = this.user ? this.user.role.id : '';
    this.commonHttpService.getArrayList({}, CommonUrlConfig.EndPoint.intake.menu + userID + '/' + roleId,
      'menu').subscribe(result => {
        if (result && result.length) {
          this.menuList = result.filter(ele => ele.assettype === '45M');
        }
      });
  }

  redirectTo(url) {
    if (url.startsWith("http")) {
      window.open(url,'_blank');
    } else {
      this.router.navigate([url], {relativeTo: this.activatedRoute});
    }
  }

  loadUserData() {
    this.http.get(environment.baseUrl + PortConfig.port['Auth'].id + '/' +
    CommonUrlConfig.EndPoint.auth.user).subscribe((result) => {
      this.user = result;
      this.localstorage.setObj('user', result);
      if(this.user?.userWorkInfo?.organization?.logourl){
        // this.PagesComponent.isShow=true;
        this.dataStore.setData('logoUrl',this.user.userWorkInfo.organization.logourl);
      }
      else{
        // this.PagesComponent.isShow=false;

      }
      if(this.user.photoUrl){
        this.userImage = environment.s3BucketUrl + '/' + this.user.photoUrl
      }
      else{
        this.userImage='../../../assets/images/deafult-pic.jpg'
      }
      this.loadMenu();
    }, error => {
      console.log(error);
      alert("user not found");
    });
  }
}
