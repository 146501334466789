import { Component, Inject, OnInit, Optional, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as es6printJS from "print-js";
import { AlertService } from 'src/app/@core/services/alert.services';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { TemplateBuilderService } from 'src/app/@core/services/template-builder.service';
import { InvoiceBuilderService } from '../letter-builder/invoice-builder.service';
import { AuthService } from 'src/app/@core/services/auth.service';
@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {


  documentURL: string;
  documentType: string;
  documentName: string;
  emailForm: FormGroup;
  showEmailSender: boolean = false;
  isInprogress = false;
  enableEmail = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Email Body..',
    translate: 'no',
    showToolbar: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  commentconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Comments..',
    translate: 'no',
    showToolbar: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  isInvoice: boolean = false;
  invoiceAction: any;
  invoiceHelper: any;
  isInvoiceEditable = false;
  sendingInprogress = false;
  invoiceStatusHelper = {
    "PREVIEW": { 'actionText': 'Generate', 'helperText': 'The below action will update all the associated claims in this invoice as Recovered and Invoicing', 'nextStatus': 'GENERATED', 'claim_status': 'Recovered and Invoicing' },
    "GENERATED": { 'actionText': 'Invoiced', 'helperText': 'The below action will update all the associated claims in this invoice as Recovered and Invoiced', 'nextStatus': 'INVOICED', 'claim_status': 'Recovered and Invoiced' },
    "INVOICED": { 'actionText': 'PAID', 'helperText': 'The below action will update all the associated claims in this invoice  as Recovered and Paid', 'nextStatus': 'PAID', 'claim_status': 'Recovered and Paid' },
    "PAID": { 'actionText': '', 'helperText': 'All the associated claims in this invoice  is Recovered and Paid', 'nextStatus': '' }
  }
  invoiceForm: FormGroup;
  email_dl_template_name = 'DL-TEMPLATE';
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<DocumentViewerComponent>,
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private commonHttpService: CommonHttpService,
    private templateBuilder: TemplateBuilderService,
    private invoiceService: InvoiceBuilderService,
    private auth: AuthService)
       {
    if (data && data.action && data.action.invoicenumber) {
      this.isInvoice = true;
      this.invoiceAction = data.action;
      if(data.action.paidunpaid != undefined) {
        this.isInvoiceEditable = true;
      } else {
        this.isInvoiceEditable = false;
      }
      if (this.auth.getRoleKey() === 'FINANCEUSER') {
        this.isInvoiceEditable = false
      }

    } else {
      this.isInvoice = false;
      this.invoiceAction = null;
      this.isInvoiceEditable = false;
    }
    if(this.data && this.data.info && this.data.info.templateData) {
      this.enableEmail = true;
    }

  }

  ngOnInit(): void {
    console.log("incomming data",this.data);
    this.documentURL = this.data.info.url;
    this.documentType = null;
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', [Validators.required]),
      body: new FormControl('', [Validators.required]),
      ccemail: new FormControl(),
      bccemail: new FormControl('',[ Validators.email]),
      attachedFileName: new FormControl(),
      attachment_path:new FormControl()
    });
    this.invoiceForm = this.formBuilder.group({
      comments: new FormControl('', [Validators.required])
    });
    this.updateInvoiceText();

    this.documentType = this.data.info.type;
    this.documentName = this.data.info.name;



  }
  setDefaultEmail() {
    if (this.data && this.data.info && this.data.info.templateData && this.data.info.attachment_path ) {
      this.emailForm.patchValue(
        { email: this.data.info.templateData.data.to_mailid,attachment_path:this.data.info.attachment_path });
    }
  }

  close() {
    this.dialogRef.close('REFRESH');
  }

  save() {
    window.open(this.documentURL, '_blank');
  }

  print() {
    es6printJS({ printable: this.documentURL, showModal: true });
  }

  email() {
    this.showEmailSender = true;
    this.sendingInprogress = false;
    this.getEmailLetterTemplate();
    this.setDefaultEmail();
  }
  sendEmail() {
    const formData = this.emailForm.getRawValue();
    this.sendingInprogress  = true;
    console.log('email',formData)
    if (this.emailForm.valid) {
      //
      formData['data'] =this.data.info.templateData.data;
      formData['template_name'] = this.email_dl_template_name;
      this.commonHttpService.create(formData, `tesla/api/v1/email`).subscribe(response => {
        if(response.issent) {
          this.alert.success(response.message);
          this.showEmailSender = false;
        } else {
          this.alert.error(response.message);
        }


        this.sendingInprogress  = false;
      },error => {
        this.alert.error("Email not sent, Please contact support team");
        this.sendingInprogress  = false;
      })

    }
  }
  getEmailLetterTemplate() {
    this.commonHttpService.getAll(`tesla/api/v1/template/email?template_type=${this.email_dl_template_name}`).subscribe(response => {
      const template = response['template'];
      const subjectTemplate = response['subject'];
      const attachedFileNameTemplate = response['attachedFileNameTemplate'];
      const templateWithValue = this.templateBuilder.update_template_values(template, this.data.info.templateData.data, '{{', '}}');

      const subject = this.templateBuilder.update_template_values(subjectTemplate, this.data.info.templateData.data, '{{', '}}');
      const attachedFileName = this.templateBuilder.update_template_values(attachedFileNameTemplate, this.data.info.templateData.data, '{{', '}}');
      const bcc = response['bcc']
      this.emailForm.patchValue({ body: templateWithValue, subject: subject, attachedFileName: attachedFileName,bccemail:bcc });
    });

  }

  cancelEmail() {
    this.showEmailSender = false;
    this.emailForm.reset();
  }

  updateInvoiceStatus() {
    const invoiceForm = this.invoiceForm.getRawValue();
    const data = {
      status: this.invoiceHelper.nextStatus,
      invoicenumbers: [this.invoiceAction.invoicenumber],
      comments: invoiceForm.comments,

    }
    this.isInprogress = true;
    let request
    if(this.invoiceAction.status === 'PREVIEW') {
      const postData = {...data,...{html:this.invoiceAction.html,claimids:this.invoiceAction.claimids}}
      request = this.invoiceService.createInvoice(postData);
    } else {
      request = this.invoiceService.updateInvoice(data);
    }
    request.subscribe(response => {
      console.log(response);
      this.isInprogress = false;
      if (response && response.status) {
        this.alert.success(`All Claims in the invoice updated as ${this.invoiceHelper.claim_status}`);

        if(response.status === 'GENERATED') {
          this.invoiceAction.comments = response.comments;
          this.invoiceAction.invoicenumber = response.invoicenumber;
          this.documentURL = response.file.url;
          this.documentName = response.file.name;
        } else {
          this.dialogRef.close('REFRESH');
        }
        this.invoiceAction.status = response.status;
        this.updateInvoiceText();



      }
    })
  }

  updateInvoiceText() {
    if (this.invoiceAction) {
      this.invoiceForm.get('comments').setValue(this.invoiceAction.comments);
      if (this.invoiceAction.status && this.invoiceStatusHelper[this.invoiceAction.status]) {
        this.invoiceHelper = this.invoiceStatusHelper[this.invoiceAction.status]

      } else {
        this.isInvoice = false;
      }
    }
  }

}
