<ng-container [ngSwitch]="documentType">
    <button class="close" mat-button (click)="close()">X</button>
    <h3 class="card-title title"><b>{{documentName}}</b></h3>

    <div *ngSwitchCase="'pdf'" class="bg-default mb-4">
        <button style="margin: 1px 1px;" (click)="save()" class="btn btn-primary btn-md pull-right" type="button">
            Download
        </button>&nbsp;&nbsp;
        <!-- <button style="margin: 1px 1px;" class="btn btn-primary btn-md pull-right" type="button">
            Fax
        </button>&nbsp;&nbsp; -->
        <button style="margin: 1px 1px;" *ngIf="enableEmail" (click)="email()" class="btn btn-primary btn-md pull-right" type="button">
            E-Mail
        </button>&nbsp;&nbsp;
        <button style="margin: 1px 1px;" (click)="print()" class="btn btn-primary btn-md pull-right" type="button">
            Print
        </button>&nbsp;&nbsp;
        <br>

    </div>
    <div *NgSwitchDefault class="bg-default mb-4">&nbsp;&nbsp;

        <button style="margin: 1px 1px;" (click)="save()" class="btn btn-primary btn-md pull-right" type="button">
            Download
        </button>&nbsp;&nbsp;
    </div>
    <ng-container *ngIf="isInvoice">

        <div class="row">
            <div class="col-12">
                <form [formGroup]="invoiceForm" >
                    <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                        <label class="col-form-label">
                            Comments

                        </label>
                        <div ref="element">
                            <angular-editor formControlName="comments" required="required" [config]="commentconfig">
                            </angular-editor>

                        </div>
                    </div>
                </form>
                <p class="helper-text" *ngIf="isInvoiceEditable">
                    {{invoiceHelper?.helperText}}
                </p>
                <button style="margin: 8px 1px;" *ngIf="invoiceHelper?.actionText && isInvoiceEditable"
                    [disabled]="isInprogress || invoiceForm.invalid " (click)="updateInvoiceStatus()"
                    class="btn btn-primary btn-md pull-right" type="button">

                    {{invoiceHelper?.actionText}}
                </button>
            </div>

        </div>
    </ng-container>
    <ng-container [ngSwitch]="documentType">


        <form [formGroup]="emailForm" *ngIf="showEmailSender">
            <div class="row">
                <div class="col-6">
                    <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                        <label class="col-form-label">
                            To Email

                        </label>
                        <div ref="element">

                            <input class="form-control" type="text" placeholder="To Email" required="required"
                                formControlName="email">

                        </div>
                    </div>
                </div>
            </div>

          <div class="row">
            <div class="col-6">
                <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                    <label class="col-form-label">
                        BCC

                    </label>
                    <div ref="element">

                        <input class="form-control" type="text" placeholder="BCC Email"
                            formControlName="bccemail">

                    </div>
                </div>
            </div>
        </div>
            <div class="row">
                <div class="col-6">
                    <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                        <label class="col-form-label">
                            Subject

                        </label>
                        <div ref="element">

                            <input class="form-control" type="text" placeholder="Subject" required="required"
                                formControlName="subject">

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                        <label class="col-form-label">
                            Body

                        </label>
                        <div ref="element">

                            <angular-editor formControlName="body" [config]="config"></angular-editor>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div ref="component" class="form-group has-feedback formio-component formio-component-textfield">
                        <label class="col-form-label">
                            <span><i class="fa fa-paperclip" title="Attached"></i></span> {{emailForm.get('attachedFileName').value}}

                        </label>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <button style="margin: 8px 1px;" (click)="sendEmail()" class="btn btn-primary btn-md pull-right"
                        [disabled]="emailForm.invalid || sendingInprogress" type="button">
                        Send
                    </button>
                    <button style="margin: 8px 1px;" (click)="cancelEmail()" class="btn btn-primary btn-md pull-right"
                        type="button">
                        Cancel
                    </button>
                </div>
            </div>
        </form>
        <ng-container [ngSwitch]="documentType">
            <ng-container *ngSwitchCase="'pdf'">

                <!-- <ng-container *ngIf="documentType === 'pdf'">
            <ngx-doc-viewer [url]="documentURL" [viewer]="google" style="width: 100%; height: 80vh"></ngx-doc-viewer>
        </ng-container> -->
                <ngx-extended-pdf-viewer [src]="documentURL"
                [showFindButton]="true"
                [handTool]="false"
                [showHandToolButton]="true"
                [showPrintButton]="false"
                [showOpenFileButton]="false"
                    [showDownloadButton]="false"
                    [showBookmarkButton]="false"
                    [useBrowserLocale]="true">
                </ngx-extended-pdf-viewer>

            </ng-container>
            <ng-container *ngSwitchCase="'worddoc'">
                <ngx-doc-viewer viewerUrl="https://docs.google.com/gview?url=%URL%&embedded=true" [url]="documentURL"
                    viewer="office" style="width: 100%; height: 80vh"></ngx-doc-viewer>
            </ng-container>
            <ng-container *ngSwitchCase="'image'">
                <div style="text-align: center"> <img style=" max-width: 100%;" [src]="documentURL">
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'video'">
                <div class="row">
                    <div class="col-12">
                <video id="my-video" class="video-js" controls preload="auto" data-setup="{}">
                    <source [src]="documentURL" type="video/mp4" />
                    <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a
                        web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                    </p>
                </video>
                </div></div>

            </ng-container>
            <ng-container *ngSwitchDefault>

                <button style="margin: 1px 1px;" (click)="save()" class="btn btn-primary btn-md pull-right"
                    type="button">
                    Download
                </button>
                <div class="text-center">This document type is not supported for browser view, please download for
                    viewing</div>
            </ng-container>

        </ng-container>
