<button class="close" mat-button (click)="close()">X</button>
<h3 class="card-title title">Search Results</h3>
<dx-data-grid id="gridContainer" [dataSource]="searchResult" [showBorders]="true">

  <dxi-column caption="Claim ID" dataField="claim_number" dataType="string" [cssClass]="'WrappedColumnClass'"
    cellTemplate="linkTemplate"> </dxi-column>
  <dxi-column caption="Referred By Adjuster" dataField="referredbyadjuster" dataType="string"> </dxi-column>
  <dxi-column caption="Referred Date" dataType="date" dataField="claimreferreddate" dataType="string"> </dxi-column>
  <dxi-column caption="Diary Date" dataType="date" dataField="diarydate" dataType="string"> </dxi-column>

  <dxi-column caption="Video in Claim" dataField="vedioinclaim" dataType="string"> </dxi-column>
  <dxi-column caption="System Status" dataField="status" dataType="string"> </dxi-column>
  <dxi-column caption="Claim Status" dataField="claim_status" dataType="string"> </dxi-column>
  <dxi-column caption="OIC Claims" dataField="oics" dataType="string"> </dxi-column>

  <div *dxTemplate="let d of 'linkTemplate'" class="dashboard-hover">
    <a href="javascript:void(0)" (click)="navigateTo(d)">
      <span> {{d.value}} </span>
    </a>
  </div>
</dx-data-grid>