<div class="row">
    <div class="col-12">
        <button style="margin: 1px 1px;" (click)="save()" class="btn btn-primary btn-md pull-right"
            type="button">
            Download
        </button>
        <button style="margin: 1px 1px;"  *ngIf="documentType == 'pdf'" (click)="print()" class="btn btn-primary btn-md pull-right"
            type="button">
            Print
        </button>
        <button style="margin: 1px 1px;" *ngIf="documentType == 'image'" (click)="printImage()" class="btn btn-primary btn-md pull-right"
            type="button">
            Print
        </button>
    </div>
</div>
<ng-container [ngSwitch]="documentType">
    <ng-container *ngSwitchCase="'pdf'">

        <!-- <ng-container *ngIf="documentType === 'pdf'">
    <ngx-doc-viewer [url]="documentURL" [viewer]="google" style="width: 100%; height: 80vh"></ngx-doc-viewer>
</ng-container> -->
        <ngx-extended-pdf-viewer [src]="documentURL" 
        [showFindButton]="true"
        [handTool]="false"
        [showHandToolButton]="true"
        [showPrintButton]="false" 
        [showOpenFileButton]="false"
            [showDownloadButton]="false" 
            [showBookmarkButton]="false"
            [showPropertiesButton]="false"
            [useBrowserLocale]="true">
        </ngx-extended-pdf-viewer>

    </ng-container>
    <ng-container *ngSwitchCase="'worddoc'">
        <ngx-doc-viewer viewerUrl="https://docs.google.com/gview?url=%URL%&embedded=true" [url]="documentURL"
            viewer="office" style="width: 100%; height: 80vh"></ngx-doc-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="'image'">
        <div style="text-align: center" id="image-container"> <img style="height: 90vh;" [src]="documentURL">
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'video'">

        <div class="row">
            
            <div class="col-12" class="video-container">
        <video id="my-video" class="video-js" controls preload="auto" width="90%" height="80%" data-setup="{}">
            <source [src]="documentURL" type="video/mp4" />
            <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
        </video>
        </div></div>
    </ng-container>
    <ng-container *ngSwitchDefault>

        <div class="text-center">This document type is not supported for browser view, please download for
            viewing</div>
    </ng-container>

</ng-container>