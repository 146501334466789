import 'rxjs/Rx';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { LocalService } from './local.service';
import { AlertService } from './alert.services';
import { CommonUrlConfig } from '../common/URLS/common-url.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { DataStoreService } from './data-store.services';
import { PortConfig } from '../common/URLS/port.config';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    //dynamic page save from workflow
    public onChangePageEventData: any;
    public onChangePageEventId: any;
    public onChangePageUserData: any;
    public onChangePersonId: any;
    public onChangeConfigData: any;

    inputFieldId : any;
    personTabName: any;
    personHealthTabName: any;
    personFinanceTabName : any;
    clickedId:any;
    rowData: any;
    caseNumberAfterApproval:any;
    public data: BehaviorSubject<any> = new BehaviorSubject(false);
    public sourceId: BehaviorSubject<any> = new BehaviorSubject('');
    public sourceIdService: BehaviorSubject<any> = new BehaviorSubject('');
    public id: BehaviorSubject<any> = new BehaviorSubject('');
    public pageId: BehaviorSubject<any> = new BehaviorSubject('');
    public moduleid: BehaviorSubject<any> = new BehaviorSubject('');
    public purposeid: BehaviorSubject<any> = new BehaviorSubject('');
    public workFlowDesign: BehaviorSubject<any> = new BehaviorSubject(null);
    public workFlowStatus: BehaviorSubject<any> = new BehaviorSubject('');
    public result: BehaviorSubject<any> = new BehaviorSubject('');
    public submitted: BehaviorSubject<any> = new BehaviorSubject(null);
    public deactivateGaurd: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(private localstore: LocalService, private http: HttpClient, private router: Router, private alert: AlertService,
        private commonHttpService: CommonHttpService,
        private dataStore: DataStoreService) {
    }
    set clickableData(data: any) {
        this.inputFieldId = data;
    }

    get clickableData() {
        return this.inputFieldId;
    }
    set personTabNameData(data: any) {
        this.personTabName = data;
    }

    get personTabNameData() {
        return this.personTabName;
    }
    set personHealthTabNameData(data: any) {
        this.personHealthTabName = data;
    }

    get personHealthTabNameData() {
        return this.personHealthTabName;
    }

    set personFinanceTabNameData(data:any){
        this.personFinanceTabName=data;
    }

    get personFinanceTabNameData(){
        return this.personFinanceTabName;
    }

    set clickedIdData(data: any) {
        this.clickedId = data;
    }

    get clickedIdData() {
        return this.clickedId;
    }
    set caseNumberAfterApprovalData(data: any) {
        this.caseNumberAfterApproval = data;
    }

    get caseNumberAfterApprovalData() {
        return this.caseNumberAfterApproval;
    }

    set clickedRowData(data: any) {
        this.rowData = data;
    }

    get clickedRowData() {
        return this.rowData;
    }

    //dynamic page save from workflow
    savePageDynamic(sourceid,pageid) {
        console.log(this.onChangePageEventData);
        let dynamicSourceid = '';
        const submissionData = this.onChangePageEventData;
        const data = submissionData.data && submissionData.data.data && submissionData.data.data.editGrid ? submissionData.data.data : submissionData.data;
        // have to change this dynamically
        if (data?.decisionkey) {
            data.sourcetypekey = (this.onChangePageUserData?.role?.rolekey === 'IW') ? '35I' : '35C';
            data.sourceid = sourceid;
            data.userrolekey = this.onChangePageUserData?.role?.rolekey;
        }
        if (data?.commisionaryPurchaseItems) {
            sourceid = data.personId;
            dynamicSourceid = sourceid;
        }
        //To do - need to change as configurable
        if (data?.clientAccountId && pageid ==='beceff49-28f4-4c87-bd6a-01f984acf950') { 
            sourceid = data.clientAccountId;
            dynamicSourceid = sourceid;
        }
        this.dataStore.setData('dynamicSourceid', dynamicSourceid);
        var getApiurl;
        var postApiurl;
        var putApiurl;
        var afterEntityName;
        var afterRuleAppName;
        var beforeEntityName;
        var beforeRuleAppName;
        if (this.onChangeConfigData && this.onChangeConfigData.length) {
            getApiurl = this.onChangeConfigData[0]['getapiurl'];
            postApiurl = this.onChangeConfigData[0]['postapiurl'];
            putApiurl = this.onChangeConfigData[0]['putapiurl'];
            afterEntityName = this.onChangeConfigData[0]['afterEntityName'];
            afterRuleAppName = this.onChangeConfigData[0]['afterRuleAppName'];
            beforeEntityName = this.onChangeConfigData[0]['beforeEntityName'];
            beforeRuleAppName = this.onChangeConfigData[0]['beforeRuleAppName'];
        } else {
            getApiurl = PortConfig.port['workflow'].id + '/pagedata';
            postApiurl = PortConfig.port['workflow'].id + '/pagedata/';
            putApiurl = PortConfig.port['workflow'].id + '/pagedata/';
            afterEntityName = '';
            afterRuleAppName = '';
            beforeEntityName = '';
            beforeRuleAppName = '';
        }
        const id = this.onChangePageEventData?.data?.id ? this.onChangePageEventData?.data?.id : this.onChangePageEventId ? this.onChangePageEventId : null;
        return this.commonHttpService
            .create(
                {
                    id: id,
                    pageID: pageid,
                    sourceid: sourceid,
                    sourcetype: (this.onChangePageUserData?.role?.rolekey === 'IW') ? '35I' : '35C',
                    data: data,
                    metadata: submissionData.metadata,
                    afterEntityName: afterEntityName,
                    afterRuleAppName: afterRuleAppName,
                    beforeEntityName: beforeEntityName,
                    beforeRuleAppName: beforeRuleAppName,
                    parentsourceid:null
                },
                id ? putApiurl : postApiurl, ''
            )
    }

    getHOHDetails(personIds) {
        return this.commonHttpService.getById(personIds, CommonUrlConfig.EndPoint.person.getUrl, 'person');
    }
}
