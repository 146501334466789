import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/pages/intake/dashboard', null, 'dashboard', null, false, 0),
    new Menu(2, 'Reports', '/pages/intake/dashboard', null, 'list', null, false, 0),
]


export const horizontalMenuItems = [
    new Menu(1, 'Dashboard', '/pages/intake/dashboard', null, 'dashboard', null, false, 0),
    new Menu(2, 'Reports', '/pages/intake/dashboard', null, 'list', null, false, 0),
]

