import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from '../entities/common.entities';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router,private toastr: ToastrService) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.toastr.success("Success", message);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.toastr.error("Error", message);
    }

    info(message: string, keepAfterRouteChange = false,options={}) {
        this.toastr.info("", message,options);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.toastr.warning("Warning", message, {timeOut:30000});
    }

    alert(type: AlertType, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type: type, message: message });
    }

    clear() {
        // clear alerts
        this.subject.next();
    }
    clearAllAlerts() {
        this.toastr.clear();
    }
}

