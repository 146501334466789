import { Component, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../../../theme-options';
import { LocalService } from 'src/app/@core/services/local.service';
import { AuthService } from 'src/app/@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { CommonUrlConfig } from 'src/app/@core/common/URLS/common-url.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PortConfig } from 'src/app/@core/common/URLS/port.config';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  public userImage = './assets/images/deafult-pic.jpg';
  public contactSprtImg = './assets/images/contact-support.png';
  supportEmail = 'support.subrogation@dminc.com';
  user: any;

  faCalendar = faCalendar;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(public globals: ThemeOptions,
    private localstorage: LocalService,
    private auth: AuthService,
    private http: HttpClient,
    private router: Router) {
  }

  ngOnInit() {
    this.user = this.localstorage.getObj('user');
    if (this.user) {
      if(this.user.photoUrl)
      {
        this.userImage = environment.s3BucketUrl + '/' + this.user.photoUrl
      }
      else
      {
        this.userImage='../../../../../../assets/images/deafult-pic.jpg'
      }
      // this.loadMenu();
    }  else {
      this.loadUserData();
    }
  }

	logout() {
		this.auth.logout();
	}


  loadUserData() {
    this.http.get(environment.baseUrl + PortConfig.port['Auth'].id + '/' +
    CommonUrlConfig.EndPoint.auth.user).subscribe((result) => {
      this.user = result;
      this.localstorage.setObj('user', result);

      if(this.user.photoUrl){
        this.userImage = environment.s3BucketUrl + '/' + this.user.photoUrl
      }
      else{
        this.userImage='../../../assets/images/deafult-pic.jpg'
      }
    }, error => {
      console.log(error);
      alert("user not found");
    });
  }

  toggleShowChat(){
    console.log("Userbox show chat");
    this.auth.handleShowChat();
  }

  showChatHistory(){
    console.log("show chat history");
    this.router.navigate(['/pages/chat-history']);
  }

  redirectToProfile() {
    console.log("profile");
    this.router.navigate(['/pages/mergepage/e98e39dd-c0dc-47e3-a476-528d50cf304f/'+this.user.id]);
  }
}

