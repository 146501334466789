<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="{{contactSprtImg}}" alt="" class="rounded-circle">
                </div>
              </span>
            </button>


            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 "></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" src="{{contactSprtImg}}" alt="" class="rounded-circle">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">Subrogation Support</div>
                          <div class="widget-subheading opacity-8">For technical issues, contact <a style="color: white;" href = "mailto: {{supportEmail}}">{{supportEmail}}</a></div>
                        </div>
                        <!-- <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus" [cdkCopyToClipboard]="supportEmail" >Copy</button>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="{{userImage}}" alt="" class="rounded-circle">
                </div>
              </span>
            </button>


            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" src="{{userImage}}" alt="" class="rounded-circle">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{user?.firstName}} {{user?.lastName}}</div>
                          <div class="widget-subheading opacity-8">{{(user?.role && user?.role.rolename) ? user?.role.rolename : ''}}</div>
                        </div>

                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="logout()">Logout</button>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
               
              </div>
              <div class="scroll-area-xs" >
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column user-ac-wrp">
                    <!-- <li class="nav-item-header nav-item">Activity</li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link" (click)="toggleShowChat()">Chat
                    
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link" (click)="showChatHistory()">Chat History
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Recover Password</a></li> -->
                    <li class="nav-item-header nav-item">My Account</li>
                    <li class="nav-item"><a href="javascript:void(0)" (click)="redirectToProfile()" class="nav-link">Profile
                        
                      </a></li>
                    <!-- <li class="nav-item"><a [routerLink]="" class="nav-link">Messages
                        <div class="ml-auto badge badge-warning">512</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Logs</a></li> -->
                  </ul>
                </perfect-scrollbar>
              </div>
              <ul class="nav flex-column" *ngIf="false">
                <li class="nav-item-divider mb-0 nav-item"></li>
              </ul>
              <div class="grid-menu grid-menu-2col overflow-hidden" *ngIf="false">
                <div class="no-gutters row">
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                      <i class="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>
                      Message Inbox
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                      <i class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                      <b>Support Tickets</b>
                    </button>
                  </div>
                </div>
              </div>
              <ul class="nav flex-column" *ngIf="false">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-wide btn btn-primary btn-sm">Open Messages</button>
                </li>
              </ul>
            </div>
          </div>

        </div>
        <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading">{{user?.firstName}} {{user?.lastName}}</div>
          <div class="widget-subheading">{{(user?.role && user?.role.rolename) ? user?.role.rolename : ''}}</div>
        </div>
        <!-- <div class="widget-content-right header-user-info ml-3">
          <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa-icon [icon]="faCalendar" class="mr-1 ml-1"></fa-icon>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="header-btn-lg" *ngIf="false">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div>
</div>
<div>

</div>
