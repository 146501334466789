<div class="h-100">
    <div class="row h-100 no-gutters">
      <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-12">
        <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-4">
          <div class="app-logo"><img src="../../../../assets/images/dmi_full_logo.png"/></div>
          <h4 class="mb-0" style="height: 350px;">
            <!-- <div><span class="dmi-col">DMI</span> Platform</div> -->
            <span>Connecting to Fleet Management...</span>
          </h4>
          <!-- <h6 class="mt-3">
            No account?
            <a [routerLink]="" class="text-primary">Sign up now</a></h6> -->
        <div class="login-footer">
            <p>Powered by
                <a href="https://dminc.com/"><img src="./../../../../assets/images/dmi_logo.png"
                        class="dmi-logo" />Digital Management, LLC</a>
                </p>
        </div>
        </div>
      </div>
    </div>
  </div>
  