import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from 'src/app/theme/pipes/search/filter.pipe';
import { MaskPipe } from './ssnMask.pipe';
import { TruncatePipe } from 'src/app/theme/pipes/truncate/truncate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        MaskPipe,
        FilterPipe,
        TruncatePipe
    ],
    exports: [
        MaskPipe,
        FilterPipe,
        TruncatePipe
    ],
    providers: [
        MaskPipe,
        FilterPipe,
        TruncatePipe
    ]
})
export class SharedPipesModule {}
