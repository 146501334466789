<!-- 3730314 -->
<!-- {{templateFormGroup.get('htmlcontent').value}} -->
<!-- {{sourceid}} -->
<button class="close" mat-button (click)="close()">X</button>
<h3 class="card-title title">{{template_name}} Template Preview</h3>
<form [formGroup]="templateFormGroup">
  <ng-container *ngIf="pageLoaded">
    <div class="formio-component">

      <div ref="component" class="formio-component formio-component-form formio-component-label-hidden" id="e3a3t2f">
        <div novalidate="" ref="webform" class="formio-form">
          <div ref="component" class="
            formio-component formio-component-panel formio-component-claimActions
            tesla-panel tesla-panel-filters
          " id="template-builder-claim-panel">
            <div class="mb-2 card border">
              <div class="bg-default">
                <button [disabled]="isValidTemplate != true" (click)="generateTemplate()"
                  class="btn btn-primary btn-md pull-right" type="button">
                  Generate {{template_name}}
                </button>
              </div>
              <div ref="header" class="card-header bg-default">
                <span class="mb-0 card-title">                  
                  Letter Content
                </span>
                <div>                 
              </div>
              </div>
              <div class="card-body">
                <div class="row" *ngIf="template_type == 'demand_letter' || template_type == 'notice_of_subrogation'">
                  <div class="col-3 template-forminputs">
                    <formio [form]="jsonForm" (change)="onChangeForm($event)" [submission]="submittedData"></formio>
                  </div>
                  <div class="col-9 bg-default">

                    <div style="
                      background-color: #9e9e9e;
                      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=);
                      -webkit-transition: left 500ms;
                      transition: left 500ms;
                      padding: 10px 10px;
                    ">
                      <div style="
                        background-color: #ffffff;
                        max-width: 820px;
                        height: auto;
                        margin: 0 auto;
                        position: relative;
                      ">
                        <angular-editor formControlName="htmlcontent" [config]="config"></angular-editor>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="template_type == 'total_loss'">
                  <!-- <div class="col-3 template-forminputs">
                    <formio [form]="jsonForm" (change)="onChangeForm($event)" [submission]="submittedData"></formio>
                  </div> -->
                  <div class="col-12 bg-default">

                    <div style="
                      background-color: #9e9e9e;
                      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=);
                      -webkit-transition: left 500ms;
                      transition: left 500ms;
                      padding: 10px 10px;
                    ">
                      <div style="
                        background-color: #ffffff;
                        max-width: 820px;
                        height: auto;
                        margin: 0 auto;
                        position: relative;
                      ">
                        <angular-editor formControlName="htmlcontent" [config]="config"></angular-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div *ngIf="template_type == 'demand_letter'" ref="component" class="formio-component formio-component-form formio-component-label-hidden" id="e3a3t2f">
      <div novalidate="" ref="webform" class="formio-form">
        <div ref="component" class="
          formio-component formio-component-panel formio-component-claimActions
          tesla-panel tesla-panel-filters
        " id="template-builder-attachment-panel">
          <div class="mb-2 card border">
            <div ref="header" class="card-header bg-default">
              <span class="mb-0 card-title">
                <i *ngIf="attachments_list_expanded" (click)="collapse_attachments_panel()" data-title="Collapse Panel"
                  class="formio-collapse-icon fa fa-minus-square-o text-muted"></i>
                <i *ngIf="!attachments_list_expanded" (click)="expand_attachments_panel()" data-title="Collapse Panel"
                  class="formio-collapse-icon fa fa-plus-square-o text-muted"></i>
                Attachments List <span style="
              font-size: 10px !important;" *ngIf="selected_attachments.length > 0"> ( {{selected_attachments.length}}
                  Item selected )</span>                  
              </span>
              <div class="ml-3">
                <button (click)="attachmetConfirmation()" *ngIf="selected_attachments.length > 0"
                  class="btn btn-primary btn-md pull-right text-lowercase" type="button">
                  Unselect All
                </button>
              </div>              
            </div>
            <div class="card-body" *ngIf="attachments_list_expanded">
              <div class="row">
                <div>
                  <div>
                    <div _ngcontent-fap-c905="" class="card-body claimattachment" style="border: 0">
                      <dx-data-grid _ngcontent-fap-c905="" width="100%" ng-reflect-width="100%"
                        ng-reflect-remote-operations="true" ng-reflect-allow-column-reordering="true"
                        ng-reflect-allow-column-resizing="true" ng-reflect-column-auto-width="true"
                        ng-reflect-show-borders="false" ng-reflect-row-alternation-enabled="true"
                        ng-reflect-show-column-lines="false" ng-reflect-show-row-lines="true"
                        class="dx-widget dx-visibility-change-handler" role="presentation" style="width: 100%"
                        ng-reflect-data-source="[object Object]">
                        <div class="" role="grid" aria-label="Data grid" aria-rowcount="104" aria-colcount="4"
                          style="padding-top: 10 !important">
                          <div class="dx-hidden"></div>
                          <div class="
                            dx-datagrid-columns-separator
                            dx-datagrid-columns-separator-transparent
                          " style=""></div>
                          <div class="dx-datagrid-tracker dx-state-invisible" style=""></div>
                          <div class="dx-hidden"></div>
                          <div class="dx-datagrid-headers dx-datagrid-nowrap" role="presentation"
                            style="padding-right: 0px">
                            <div class="
                              dx-datagrid-content dx-datagrid-scroll-container
                            " role="presentation">
                              <table class="dx-datagrid-table dx-datagrid-table-fixed" role="presentation">
                                <colgroup>
                                  <col style="width: 45px" />
                                  <col style="width: 537.906px" />
                                  <col style="width: 156.625px" />
                                </colgroup>
                                <tbody role="presentation" class="">
                                  <tr class="dx-row dx-header-row" role="row">
                                    <td aria-selected="false" role="columnheader" aria-colindex="1" id="dx-col-9"
                                      aria-label="Column Describtion" class="
                                      dx-datagrid-action
                                      dx-cell-focus-disabled
                                      dx-datagrid-drag-action
                                    " aria-sort="none" tabindex="0" style="text-align: left">
                                      <div class="
                                        dx-datagrid-text-content
                                        dx-text-content-alignment-left
                                        dx-header-filter-indicator
                                      " role="presentation"></div>
                                    </td>
                                    <td aria-selected="false" role="columnheader" aria-colindex="2" id="dx-col-9"
                                      aria-label="Column Describtion" class="
                                      dx-datagrid-action
                                      dx-cell-focus-disabled
                                      dx-datagrid-drag-action
                                    " aria-sort="none" tabindex="0" style="text-align: left">
                                      <div class="
                                        dx-datagrid-text-content
                                        dx-text-content-alignment-left
                                        dx-header-filter-indicator
                                      " role="presentation">
                                        Description
                                      </div>
                                    </td>
                                    <td aria-selected="false" role="columnheader" aria-colindex="3" id="dx-col-10"
                                      aria-label="Column Created by" class="
                                      dx-datagrid-action
                                      dx-cell-focus-disabled
                                      dx-datagrid-drag-action
                                    " aria-sort="none" tabindex="0" style="text-align: left">
                                      <div class="
                                        dx-datagrid-text-content
                                        dx-text-content-alignment-left
                                        dx-header-filter-indicator
                                      " role="presentation">
                                        Format
                                      </div>
                                    </td>
                                    <td aria-selected="false" role="columnheader" aria-colindex="3" id="dx-col-10"
                                      aria-label="Column Created by" class="
                                    dx-datagrid-action
                                    dx-cell-focus-disabled
                                    dx-datagrid-drag-action
                                  " aria-sort="none" tabindex="0" style="text-align: left">
                                      <div class="
                                      dx-datagrid-text-content
                                      dx-text-content-alignment-left
                                      dx-header-filter-indicator
                                    " role="presentation">
                                        Preview
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="
                            dx-datagrid-rowsview
                            dx-datagrid-nowrap
                            dx-scrollable
                            dx-visibility-change-handler
                            dx-scrollable-both
                            dx-scrollable-simulated
                          " role="presentation">
                            <div class="dx-scrollable-wrapper">
                              <div class="dx-scrollable-container" style="touch-action: pan-y">
                                <div class="dx-scrollable-content" style="left: 0px; top: 0px; transform: none">
                                  <div class="" style="
                                    min-height: 100px;
                                    overflow-y: scroll;
                                    max-height: 260px;
                                  ">
                                    <table class="
                                      dx-datagrid-table dx-datagrid-table-fixed
                                    " role="presentation" style="table-layout: fixed">
                                      <colgroup style="">
                                        <col style="width: 45px" />
                                        <col style="width: 537.906px" />
                                        <col style="width: 156.625px" />
                                      </colgroup>
                                      <tbody role="presentation">
                                        <tr class="dx-row dx-data-row dx-row-lines" role="row" aria-selected="false"
                                          aria-rowindex="1" *ngFor="let attachment of attachments">
                                          <td aria-describedby="dx-col-12" aria-selected="false" role="gridcell"
                                            aria-colindex="1" style="
                                            text-align: center !important;
                                            top: 10px;
                                            position: relative;
                                          ">
                                            <div _ngcontent-fap-c905="" class="dx-template-wrapper"
                                              style="text-align:center !important;">
                                              <input
                                                [disabled]="attachment.format == 'Other' || attachment.format == 'Video'"
                                                *ngIf="getAttachmentIndex(attachment.s3_path) == -1 && attachment.format != 'Other'  && attachment.format != 'Video'"
                                                type="checkbox" (click)="
                                                toggle_attachment(
                                                  attachment.s3_path
                                                )
                                              " />
                                              <input
                                                title="Unsupported File Format ( Supporting Formats : Image, Pdf, Word Document )"
                                                [disabled]="attachment.format == 'Other' || attachment.format == 'Video'"
                                                *ngIf="attachment.format == 'Other' || attachment.format == 'Video'"
                                                type="checkbox" (click)="
                                              toggle_attachment(
                                                attachment.s3_path
                                              )
                                            " />
                                              <a (click)="
                                            toggle_attachment(
                                              attachment.s3_path
                                            )
                                          " *ngIf="getAttachmentIndex(attachment.s3_path) > -1"
                                                style="
                                            background: #3ac47d;
                                            color: white;min-width: 17px;
                                            min-height: 17px;position: relative;display: inline-block;border-radius: 6px;text-align: center;cursor: pointer;font-size: 12px !important;">
                                                {{getAttachmentIndex(attachment.s3_path)+1}}</a>
                                            </div>
                                          </td>
                                          <td aria-describedby="dx-col-9" aria-selected="false" role="gridcell"
                                            aria-colindex="2" tabindex="0" style="
                                            text-align: left !important;
                                            top: 10px;
                                            position: relative;
                                          ">
                                            <div _ngcontent-fap-c905="" ng-reflect-ng-class="[object Object]"
                                              class="dx-template-wrapper">

                                              <div [innerHTML]="truncateIfExceeds(attachment.describtion)"></div>

                                              <a href="javascript:void(0)"><span class="clickable"
                                                  *ngIf="attachment?.describtion?.length > TRUNCATE_TEXT_LENGTH"
                                                  (click)="popup(attachment.describtion,'Description')">Read
                                                  more</span></a>
                                            </div>
                                          </td>
                                          <td aria-describedby="dx-col-10" aria-selected="false" role="gridcell"
                                            aria-colindex="3" style="
                                            text-align: center center !important;
                                            top: 10px;
                                            position: relative;
                                          ">
                                            <div title="{{ attachment.name }}" _ngcontent-fap-c905=""
                                              ng-reflect-ng-class="[object Object]" class="dx-template-wrapper">
                                              {{ attachment.format }}
                                            </div>
                                          </td>
                                          <td aria-describedby="dx-col-10" aria-selected="false" role="gridcell"
                                            aria-colindex="3" style="
                                          text-align: center center !important;
                                          top: 10px;
                                          position: relative;
                                        ">
                                            <button (click)="viewAttachment(attachment)" class="no-bg ng-star-inserted">
                                              <i class="fa fa-eye"></i></button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <span class="dx-datagrid-nodata dx-hidden">No data</span>
                        </div>

                        <div></div>
                      </dx-data-grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


</form>
<ng-template #readMoreDialogPopup>
  <h2 matDialogTitle>{{popupTitle}}</h2>
  <div [innerHTML]="popupData"></div>

  <div class="text-right">
    <button [cdkCopyToClipboard]="popupData" mat-button class="btn btn-sec mr-2"> Copy </button>
    <button mat-button (click)="closeDialogPopup()" class="btn btn-sec mr-2"> Close </button>
  </div>
</ng-template>

<ng-template #demandLetterStatusPopup>
  <button class="close" mat-button (click)="closeDLDialogPopup()">X</button>
  <h2 matDialogTitle>{{dlpopupTitle}}</h2>

  <div *ngIf="!isDLStatusChecking" [innerHTML]="dlpopupData"></div>
  <div *ngIf="isDLStatusChecking" style="display:inline-block;vertical-align: middle;">
    <img src="assets/images/tesla/gif/loading.gif" alt="loading..." height="35px" width="35px">
    Checking Demand Letter Status.Please Wait.
  </div>
  <br /><br />
  <div class="text-right" *ngIf="!isDLStatusChecking">
    <img class="sub-icon" (click)="checkdemandletterStatus(dlid)" src="../../../assets/images/svg/refresh_reload.svg" />
    <!-- <button mat-button [disabled]="isDLStatusChecking" (click)="checkdemandletterStatus(dlid)" mat-button class="btn btn-primary mr-2"> Reload </button> -->
    <!-- <button mat-button  (click)="closeDLDialogPopup()" class="btn btn-primary mr-2"> Close </button> -->
  </div>
</ng-template>

<ng-template #demandLetterErrorStatusPopup>
  <button class="close" mat-button (click)="closeDLErrorDialogPopup()">X</button>
  <h2 matDialogTitle>{{dlErrorPopupTitle}}</h2>
  <div [innerHTML]="dlErrorPopupData"></div>
</ng-template>

<ng-template #confirmSelectedAttachments>
  <h2 matDialogTitle>Confirmation</h2>
  <p> Are you sure you want to unselect the selected attachments? </p>

  <div class="text-right">
      <button class="btn btn-sec mr-2" mat-button (click)="closePopup()">No</button>
      <button class="btn btn-pri" mat-button (click)="removeRecord()">Yes</button>
  </div>
</ng-template>