<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: 600px;">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Main Navigation</div>
                </ng-template>
              </ngb-panel> -->
              <ng-container *ngFor="let item of menuList;let i = index">
                <ngb-panel id="menu-{{i}}">
                  <ng-template ngbPanelHeader>
                    <button [routerLink]="item?.url" ngbPanelToggle class="vsm-link">
                      <!-- <i class="vsm-icon pe-7s-rocket"></i> -->
                      <mat-icon class="vsm-icon material-icons">{{item.icon}}</mat-icon>
                      <span class="vsm-title">{{item.assetname}}</span>
                      <i class="vsm-arrow" *ngIf="item.children && item.children.length"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent *ngIf="item.children && item.children.length">
                    <!-- first child -->
                    <div class="vsm-dropdown sub-menu">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let childItem of item.children">
                          <span *ngIf="childItem.children.length" ngbPanelHeader>
                            <button (click)="redirectTo(childItem?.url)" class="vsm-link">
                              <!-- <i class="vsm-icon pe-7s-rocket"></i> -->
                              <mat-icon class="vsm-icon material-icons">{{childItem.icon}}</mat-icon>
                              <span class="vsm-title">{{childItem.assetname}}</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </span>

                          <span *ngIf="childItem.children.length === 0">
                            <a (click)="redirectTo(childItem?.url)"  routerLinkActive="active-item"
                              [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                              <mat-icon class="vsm-icon material-icons">{{childItem.icon}}</mat-icon>
                              <span class="vsm-title">{{childItem?.assetname}}</span>
                            </a>
                          </span>
                          <!-- first child end -->
                          <!-- second child  -->
                          <div class="vsm-dropdown" *ngIf="childItem.children.length">
                            <div class="vsm-list">
                              <div class="vsm-item" *ngFor="let subchild of childItem.children">
                                <span *ngIf="subchild.children.length" ngbPanelHeader>
                                  <button [routerLink]="subchild?.url" class="vsm-link">
                                    <!-- <i class="vsm-icon pe-7s-rocket"></i> -->
                                    <mat-icon class="vsm-icon material-icons">{{subchild.icon}}</mat-icon>
                                    <span class="vsm-title">{{subchild.assetname}}</span>
                                    <i class="vsm-arrow"></i>
                                  </button>
                                </span>

                                <span *ngIf="subchild.children.length === 0">
                                  <a [routerLink]="subchild?.url" routerLinkActive="active-item"
                                    [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                                    <mat-icon class="vsm-icon material-icons">{{subchild.icon}}</mat-icon>
                                    <span class="vsm-title">{{subchild?.assetname}}</span>
                                  </a>
                                </span>
                                <!-- second child end -->
                                <!-- third child -->
                                <div class="vsm-dropdown" *ngIf="subchild.children.length">
                                  <div class="vsm-list">
                                    <div class="vsm-item" *ngFor="let child of subchild.children">
                                      <span *ngIf="child.children.length" ngbPanelHeader>
                                        <button [routerLink]="child?.url" class="vsm-link">
                                          <!-- <i class="vsm-icon pe-7s-rocket"></i> -->
                                          <mat-icon class="vsm-icon material-icons">{{child.icon}}</mat-icon>
                                          <span class="vsm-title">{{child.assetname}}</span>
                                          <i class="vsm-arrow"></i>
                                        </button>
                                      </span>

                                      <span *ngIf="child.children.length === 0">
                                        <a [routerLink]="child?.url" routerLinkActive="active-item"
                                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                                          <mat-icon class="vsm-icon material-icons">{{child.icon}}</mat-icon>
                                          <span class="vsm-title">{{child?.assetname}}</span>
                                        </a>
                                      </span>
                                      <!-- third child end -->
                                      <!-- fouth child -->
                                      <div class="vsm-dropdown" *ngIf="child.children.length">
                                        <div class="vsm-list">
                                          <div class="vsm-item" *ngFor="let lastChild of child.children">
                                            <a [routerLink]="lastChild?.url" routerLinkActive="active-item"
                                              [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                                              <mat-icon class="vsm-icon material-icons">{{lastChild.icon}}</mat-icon>
                                              <span class="vsm-title">{{lastChild?.assetname}}</span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- fouth child div end -->

                                    </div>
                                  </div>
                                </div>
                                <!-- third child div end -->
                              </div>
                            </div>
                          </div>
                          <!-- second child div end -->
                        </div>
                      </div>
                    </div>
                    <!-- first child div end -->
                  </ng-template>
                </ngb-panel>
              </ng-container>
              <!-- <app-menucontent  *ngIf="menuList?.length" [menuItems]="menuList"></app-menucontent> -->
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>