import { Injectable } from "@angular/core";
import { CommonUrlConfig } from "../common/URLS/common-url.config";
import { AlertService } from "./alert.services";
import { CommonHttpService } from "./common-http.service";
import { TemplateBuilderComponent } from "src/app/shared/modules/template-builder/template-builder.component"; 
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private commonHttpService: CommonHttpService,private alert:AlertService,public dialog: MatDialog) {}
    sendNotificationFromWorkFlow(BasicControllerDTOWhithMap,sourceId,pageID){
       BasicControllerDTOWhithMap.workflowData = BasicControllerDTOWhithMap.data;
       var assignUserId = BasicControllerDTOWhithMap.workflowData ? BasicControllerDTOWhithMap.workflowData.assignUserId : '';
       var notification_config_id =  BasicControllerDTOWhithMap.workflowData && BasicControllerDTOWhithMap.workflowData.action
       ? BasicControllerDTOWhithMap.workflowData.action.notification_config_id : "";
       BasicControllerDTOWhithMap.pageID = pageID;
       BasicControllerDTOWhithMap.data =
       {
            "userid": assignUserId,
            "id":null,
            "message":null,"isread":false,"priority":"high",
            "sourceid":sourceId,
            "sourcetypekey":null,"sourceno":null
       };
       BasicControllerDTOWhithMap.notificationData =  BasicControllerDTOWhithMap.notificationData;
       BasicControllerDTOWhithMap.sourceid=sourceId;
       if(notification_config_id) {
            this.commonHttpService
            .create(
                BasicControllerDTOWhithMap ,
                CommonUrlConfig.EndPoint.notification.post.replace('{id}',notification_config_id), 'communication'
            )
            .subscribe((result) => {
                console.log(result);
            });
        }
    }
    sendNotification(BasicControllerDTOWhithMap){
       BasicControllerDTOWhithMap.notificationData = BasicControllerDTOWhithMap.data;
       var sourceID = BasicControllerDTOWhithMap.notificationData.sourceid ? BasicControllerDTOWhithMap.notificationData.sourceid : '';
       var insertedBy = BasicControllerDTOWhithMap.notificationData.insertedBy ? BasicControllerDTOWhithMap.notificationData.insertedBy : '';
       BasicControllerDTOWhithMap.data =
       {
            "userid": insertedBy,
            "id":null,
            "message":null,"isread":false,"priority":"high",
            "sourceid":sourceID,
            "sourcetypekey":null,"sourceno":null
       };
       this.commonHttpService
      .create(
        BasicControllerDTOWhithMap,
        CommonUrlConfig.EndPoint.notification.post.replace('{id}',BasicControllerDTOWhithMap.pageID), 'communication'
      )
      .subscribe((result) => {
         console.log(result);
      });
    }

    flashSourceNotifications(sourceid) {
        this.alert.clearAllAlerts();
        this.commonHttpService.getArrayList({},`tesla/api/v1/claim/${sourceid}/notifications`).subscribe(response => {
            if(response && response.notifications && response.notifications.length)
            {
              response.notifications.forEach(notification => {
                console.log('notification: ',notification)
                
                if (notification.generate_demandletter == true){
                  this.autoDemandLetter(sourceid);
                }  
                this.alert.info(notification.msg,true,{closeButton:true,disableTimeOut : true});
              })
            }
          })
    }

    autoDemandLetter(sourceid) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.height = '90%';
      dialogConfig.width = '100%';
      dialogConfig.maxWidth = '98vw';
      dialogConfig.maxHeight = '90vh';
      dialogConfig.data = { sourceid:sourceid,type:'demand_letter'};
      console.log('dialogConfig: ',dialogConfig)
      const ref = this.dialog.open(TemplateBuilderComponent, dialogConfig);
      ref.afterClosed().subscribe(result => {
        console.log("dialog closed")
      });
    }
}
