import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TitleCaseDirective } from './title-case-directive';
import { NumberOnlyDirective } from './number-only.directive';
import { ZipcodeOnlyDirective } from './zipcode-only.directive';
import { StringOnlyAllowSpaceDirective } from './string-only-allow-space.directive';
import { NgControl, ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [TitleCaseDirective, NumberOnlyDirective, ZipcodeOnlyDirective, StringOnlyAllowSpaceDirective],
    exports: [TitleCaseDirective, NumberOnlyDirective, ZipcodeOnlyDirective, StringOnlyAllowSpaceDirective],
    providers: []
})

export class SharedDirectivesModule { }
