import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/@core/services/alert.services';
import { CommonHttpService } from '../../../@core/services/common-http.service';

@Component({
  selector: 'app-loader-comp',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  deleteId: string;
  message: string;
  url: string;
  portConfig: string;
  successMsg: string;
  action: string;
  data: any;
  value: any;
  showSuccessImage: boolean = false;
  showLoadingImage : boolean = false;
  imageSrc : any;

  constructor(
    private commonHttpService: CommonHttpService,
    private alert: AlertService,
    public dialogRef: MatDialogRef<LoaderComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

      this.message = data?.message ? data.message : 'Loading Please Wait...';
      this.successMsg = data?.successMsg ? data.successMsg : 'Loaded Successfully';
      this.showSuccessImage = data?.showSuccessImage ? data.showSuccessImage : false;
      this.showLoadingImage = data?.showLoadingImage ? data.showLoadingImage : false;
      this.data=data
      this.imageSrc = this.showSuccessImage ? 'assets/images/tesla/gif/success.gif' :"assets/images/tesla/gif/loading.gif";
  
  }

  ngOnInit(): void {
  }




 

}
