import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.sass']
})
export class SearchListComponent implements OnInit {

  
  
  searchResult:any[];
  constructor(public dialogRef: MatDialogRef<SearchListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    private _location: Location) { }

  ngOnInit(): void {
    console.log(this.data);
    this.searchResult = this.data.response;
  }

  close() {
    this.dialogRef.close();
  }

  navigateTo(data) {
    const url = this._location.prepareExternalUrl(this.router.serializeUrl(
      this.router.createUrlTree([data.data.link_url])
    ));
    console.log(url)
  
    window.open(url, '_blank');
  }

}
