import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CommonHttpService } from 'src/app/@core/services/common-http.service';
import { TemplateBuilderService } from 'src/app/@core/services/template-builder.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceBuilderService {

  templateHTML = '';

  constructor(private commonHttpService: CommonHttpService,
    private builderService:TemplateBuilderService,
    private _date: DatePipe) {

   }
   getLetterTemplate(selected_source) {
    return this.commonHttpService.getAll( `tesla/api/v1/template/letter?letter_type=${selected_source}`);    
  }

  getClaimListHTML(claims) {

    let claimListHTML = '';
    let amountrecoveredtotal = 0;
    let amountinvoicedtotal = 0;

    if(Array.isArray(claims) && claims.length > 0 )
    {
      let finalMap = new Map<string,Array<number>>();
      let count =0
      claims.forEach(claimDetail =>{
        console.log('ID'+claimDetail.claimid+" amount recovered"+claimDetail.amountrecoverednum+" invoice amount"+claimDetail.invoiceamountnum);
        let claimIDstr = (claimDetail.claimid).split(".")[0];
        if(finalMap.get(claimIDstr)== null){
          finalMap.set(claimIDstr,[claimDetail.amountrecoverednum,claimDetail.invoiceamountnum])
        }else{
          let values = finalMap.get(claimIDstr);
          let total1 = 0;
          let total2 = 0;
          if(values.length!=0){
            total1 = values[0]+claimDetail.amountrecoverednum;
            total2 = values[1]+claimDetail.invoiceamountnum;
            finalMap.set(claimIDstr,[total1,total2]);
          }
        }
      })
      console.log(finalMap);
      finalMap.forEach((value: Array<number>, key: string) => {
        console.log(key, value[0],value[1]);
        claimListHTML += `<tr><td>${key}</td><td>${ parseFloat(value[0].toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td><td>${parseFloat(value[1].toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td></tr>`
        amountrecoveredtotal += value[0];
        amountinvoicedtotal += value[1];
  
      });



      // claims.forEach(claim => {
      //   claimListHTML += `<tr><td>${claim.claimid}</td><td>${claim.amountrecovered}</td><td>${claim.invoiceamount}</td></tr>`
      //   amountrecoveredtotal += claim.amountrecoverednum;
      //   amountinvoicedtotal += claim.invoiceamountnum;
      // })
      claimListHTML +=`<tr>
      <td  style='border-bottom: 0;border-left: 0;' >Total Amounts</td> 
      <td>$${parseFloat(amountrecoveredtotal.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
      <td>$${parseFloat(amountinvoicedtotal.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>`

    }

    return claimListHTML;

  }




  updateTemplate(claims) {

    const invoiceTable =  this.getClaimListHTML(claims);
    const currentDate = this._date.transform(new Date(), 'MM-dd-yyyy');
    const readabledate = this._date.transform(new Date(), 'MMMM dd, yyyy')
    const mapper = {'invoicetable':invoiceTable,
    'invoicenum':"#0000000",
    'currentdate':currentDate,
    'readabledate':readabledate};
    this.templateHTML = this.builderService.update_template_values(this.templateHTML,mapper,'{{','}}');
    
  }

  createInvoice(data) {
    return this.commonHttpService.create(data, `tesla/api/v1/invoice`);
  }

  updateInvoice(data) {
    return this.commonHttpService.updateWithoutid(data, `tesla/api/v1/invoice`);
  }
}
