export class PortConfig {
  public static port = {
      config: {
          id: 'config' //'9008'
      },
      person: {
          id: 'person' //'9001'
      },
      menu: {
          id: 'rbac' //'9004'
      },
      ribbon: {
          id: 'intake' //'9002'
      },
      assessment: {
        id: 'assessment' //'9010'
      },
      document: {
        id: 'document' //'9005'
      },
      tabconfig: {
          id:'workflow' //'9007'
      },
      workflow : {
          id:'workflow' //'9007'
      },
      rbac : {
          id: 'rbac' //'9004'
      },
      integration : {
          id:'integration' //'9003'
      },
      communication: {
          id: 'communication' //'9013'
      },
      appointment: {
          id: 'appointment' //'9014'
      },
      finance: {
        id: 'finance' // '9015'
      },
      Auth: {
        id: 'auth' // 9000
      },
      search: {
        id: 'search' // 9006
      },
      dynamicapi: {
        id: 'dynamic-api' //9017
      },
      searchinput: {
          id: 'search' // 9006
      },
      harperdb:{
        id: 'harperdb' //3000
      },
      report: {
        id: 'report' //9012
      },
      course: {
        id: 'course' // '9016'
      }
  };
}
