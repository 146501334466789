import { Injectable } from '@angular/core';
import { ArchitectUIState } from './index';
import { NgRedux } from '@angular-redux/store';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigActions {
  static CONFIG_GET = 'CONFIG_GET';
  static CONFIG_UPDATE = 'CONFIG_UPDATE';
  public configAction: BehaviorSubject<any> = new BehaviorSubject(null); 
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
  ) { }

  getConfig() {
    this.ngRedux.dispatch({
      type: ConfigActions.CONFIG_GET,
    });
  }

  updateConfig(config): void {
    this.configAction.next(config);
    this.ngRedux.dispatch({
      type: ConfigActions.CONFIG_UPDATE,
      payload: config
    });
  }

}
