import { formatDate  } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { CommonHttpService } from "./common-http.service";
@Injectable({
    providedIn: 'root'
})
export class TemplateBuilderService {
    constructor(@Inject(LOCALE_ID) private locale: string,
    private commonHttpService: CommonHttpService) {

    }
    update_template_values(template,info,prefix,suffix) {
        let updatedTemplate = template;

        for (const key in info) {
        //   console.log(info[key]);
        //   console.log(key);
          let value = "";
          if(!isNaN(info[key]) && info[key] !== null) {
            if(key == "deductible_amount" || key == "subrogation_total" || key == 'outofpocketexpense' || key == 'actual_cash_value' || key == 'taxes' || key == 'reg_title' || key == 'sca_inspection' || key == 'rental' || key == 'rental_oop' || key == 'iaa_charges' || key == 'salvage' || key == 'total_demand_amount')  {
              if(info[key] > 0) {
                console.log("key",key,info[key] )
                info[key] = Number(info[key]).toFixed(2)
              }
            value = info[key].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
            else{
              value = info[key].toString();
            }
          } else {
            if(key == "letter_date" && info[key] !== '') {
              value = formatDate(info[key], 'MMMM dd, yyyy',this.locale);
            } else if(key == "date_of_loss"  && info[key] !== '') {
              value = formatDate(info[key], 'MM/dd/yyyy',this.locale);
            }

            else{
            value = info[key];
            }
          }
          if(value != null && value != "" && updatedTemplate !== undefined){
          updatedTemplate = updatedTemplate.replaceAll(prefix + key+ suffix, value);
          }

        }

        return updatedTemplate;
      }
}
