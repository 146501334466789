import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from 'src/app/@core/services/local.service';

export class IntakeStore {
    number: string;
    action: string;
    id: string;
}
@Injectable({
    providedIn: 'root'
  })
  export class IntakeService {
    constructor(private localstore: LocalService, private router: Router,) {

    }

    redirectToIntake(intakeNumber, action) {
        this.localstore.removeItem('intake');
        const intake = Object.create(IntakeStore);
        intake.number = intakeNumber;
        intake.action = action;
        intake.id = null;
        this.localstore.setObj('intake', intake);
        this.router.navigateByUrl('pages/intake/new');
    }
  }
  