<ng-container *ngIf="window && window.isCollapsed">
	<div class="ng-chat-title secondary-background">
		<div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
			<strong title="{{window.participant.displayName}}">
				{{window.participant.displayName}}
			</strong>
			<span [ngClass]="{'ng-chat-participant-status': true, 'online': window.participant.status == ChatParticipantStatus.Online, 'busy': window.participant.status == ChatParticipantStatus.Busy, 'away': window.participant.status == ChatParticipantStatus.Away, 'offline': window.participant.status == ChatParticipantStatus.Offline}" title="{{chatParticipantStatusDescriptor(window.participant.status, localization)}}"></span>
			<span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span>
		</div>
		<a href="javascript:void(0);" class="ng-chat-close primary-text" (click)="onCloseChatWindow()">X</a>
	</div>
</ng-container>
<ng-container *ngIf="window && !window.isCollapsed">
	<div class="ng-chat-title secondary-background">
		<div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
			<strong title="{{window.participant.displayName}}">
				{{window.participant.displayName}}
			</strong>
			<span [ngClass]="{'ng-chat-participant-status': true, 'online': window.participant.status == ChatParticipantStatus.Online, 'busy': window.participant.status == ChatParticipantStatus.Busy, 'away': window.participant.status == ChatParticipantStatus.Away, 'offline': window.participant.status == ChatParticipantStatus.Offline}" title="{{chatParticipantStatusDescriptor(window.participant.status, localization)}}"></span>
			<span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span>
		</div>
		<a href="javascript:void(0);" class="ng-chat-close primary-text" (click)="onCloseChatWindow()">X</a>
		<ng-chat-options [ngClass]="'ng-chat-options-container'" [options]="defaultWindowOptions(window)" (activeOptionTrackerChange)="activeOptionTrackerChange($event)" (saveChatTrigger)="saveChatTrigger($event)"></ng-chat-options>
	</div>
	<div #chatMessages class="ng-chat-messages primary-background">
		<div *ngIf="window.isLoadingHistory" class="ng-chat-loading-wrapper">
			<div class="loader">Loading history...</div>
		</div>
		<div *ngIf="hasPagedHistory && window.hasMoreMessages && !window.isLoadingHistory" class="ng-chat-load-history">
			<a class="load-history-action" (click)="fetchMessageHistory(window)">{{localization.loadMessageHistoryPlaceholder}}</a>
		</div>

		<div *ngFor="let message of window.messages; let i = index" [ngClass]="{'ng-chat-message': true, 'ng-chat-message-received': message.fromId != userId}">
			<ng-container *ngIf="isAvatarVisible(window, message, i)">
				<div *ngIf="!getChatWindowAvatar(window.participant, message)" class="icon-wrapper">
					<i class="user-icon"></i>
				</div>
				<img *ngIf="getChatWindowAvatar(window.participant, message)" alt="" class="avatar" height="30" width="30" [src]="getChatWindowAvatar(window.participant, message) | sanitize" />
				<span *ngIf="window.participant.participantType == ChatParticipantType.Group" class="ng-chat-participant-name">{{window.participant | groupMessageDisplayName:message}}</span>
			</ng-container>
			<ng-container [ngSwitch]="message.type">
				<div *ngSwitchCase="MessageType.Text" [ngClass]="{'sent-chat-message-container': message.fromId == userId, 'received-chat-message-container': message.fromId != userId}">
          <span [innerHtml]="message.message | emojify:emojisEnabled | linkfy:linkfyEnabled"></span>
					<span *ngIf="showMessageDate && message.dateSent" class="message-sent-date">{{message.dateSent | date:messageDatePipeFormat}}</span>
				</div>
        <div *ngSwitchCase="MessageType.Image" [ngClass]="{'sent-chat-message-container': message.fromId == userId, 'received-chat-message-container': message.fromId != userId}">

          <img src="{{message.message}}" class="image-message" />

					<span *ngIf="showMessageDate && message.dateSent" class="message-sent-date">{{message.dateSent | date:messageDatePipeFormat}}</span>
				</div>
				<div *ngSwitchCase="MessageType.File" [ngClass]="{'file-message-container': true, 'received': message.fromId != userId}">
					<div class="file-message-icon-container">
						<i class="paperclip-icon"></i>
					</div>
					<a class="file-details" [attr.href]="message.downloadUrl" target="_blank" rel="noopener noreferrer" (click)="this.markMessagesAsRead([message])" download>
						<span class="file-message-title" [attr.title]="message.message">{{message.message}}</span>
						<span *ngIf="message.fileSizeInBytes"  class="file-message-size">{{message.fileSizeInBytes}} Bytes</span>
					</a>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="ng-chat-footer primary-outline-color primary-background">
		<input #chatWindowInput
			type="text"
			[ngModel]="window.newMessage | emojify:emojisEnabled"
			(ngModelChange)="window.newMessage=$event"
			[placeholder]="localization.messagePlaceholder"
			[ngClass]="{'chat-window-input': true, 'has-side-action': fileUploadAdapter}"
			(keydown)="onChatInputTyped($event, window)"
			(blur)="toggleWindowFocus(window)"
			(focus)="toggleWindowFocus(window)"/>

		<!-- File Upload -->
		<ng-container *ngIf="fileUploadAdapter">
			<a *ngIf="!isUploadingFile(window)" class="btn-add-file" (click)="triggerNativeFileUpload(window)">
				<i class="upload-icon"></i>
			</a>
			<input
				type="file"
				#nativeFileInput
				style="display: none;"
				[attr.id]="getUniqueFileUploadInstanceId(window)"
				(change)="onFileChosen(window)" />
			<div *ngIf="isUploadingFile(window)" class="loader"></div>
		</ng-container>
	</div>
</ng-container>
