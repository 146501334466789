import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// // Pages

import { ForgotPasswordComponent } from './DemoPages/UserPages/forgot-password/forgot-password.component';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
import { RegisterComponent } from './DemoPages/UserPages/register/register.component';
import { AuthGuard } from './@core/auth/auth.guard';
import { AutologinComponent } from './DemoPages/UserPages/autologin/autologin.component';
import { TempsignupComponent } from './DemoPages/UserPages/temp-signup/tempsignup.component';
import { DocViewerComponent } from './DemoPages/UserPages/doc-viewer/doc-viewer.component';

const routes: Routes = [
   {
    path: '',
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: 'login', component: LoginComponent, data: { extraParameter: '' }
      },
      {
        path: 'signup', component: TempsignupComponent, data: { extraParameter: '' }
      },
      {
        path: 'login/:userid', component: LoginComponent, data: { extraParameter: '' }
      }
      ,
      {
        path: 'autologin', component: AutologinComponent, data: { extraParameter: '' }
      },
      {
        path: 'doc-viewer/:docid', component: DocViewerComponent, data: { extraParameter: '' }
      },
      // {
      //   path: 'register', component: RegisterComponent, data: { extraParameter: '' }
      // },
      // {
      //   path: 'forgot-password', component: ForgotPasswordComponent, data: { extraParameter: '' }
      // },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ],
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      // DMI Platform Existing Copied
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'dynamicpage',
        loadChildren: () => import('./dynmic-tab-page/dynmic-tab-page.module').then(m => m.DynmicTabPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'view-dashboard', loadChildren: () => import('./dynamic-dashboard/dynamic-dashboard.module').then(m => m.DynamicDashboardModule),
        canActivate: [AuthGuard],
      },
      // Theme Layouts and components
      // {
      //   path: 'apps',
      //   loadChildren: () => import('./DemoPages/Applications/Applications.module').then(m => m.ApplicationModule)
      // },
      // {
      //   path: 'charts',
      //   loadChildren: () => import('./DemoPages/Charts/Charts.module').then(m => m.ChartModule)
      // },
      // {
      //   path: 'components',
      //   loadChildren: () => import('./DemoPages/Components/Components.module').then(m => m.ComponentsDrawerModule)
      // },
      // {
      //   path: 'dashboards',
      //   loadChildren: () => import('./DemoPages/Dashboards/Dashboards.module').then(m => m.DashboardsModule)
      // },
      // {
      //   path: 'elements',
      //   loadChildren: () => import('./DemoPages/Elements/Elements.module').then(m => m.ElementsModule)
      // },
      // {
      //   path: 'form-elements',
      //   loadChildren: () => import('./DemoPages/Forms/Elements/form-elements.module').then(m => m.FormElementModule)
      // },
      // {
      //   path: 'form-widgets',
      //   loadChildren: () => import('./DemoPages/Forms/Widgets/forms-widgets.module').then(m => m.FormWidgetsModule)
      // },
      // {
      //   path: 'button-indicators',
      //   loadChildren: () => import('./DemoPages/Material/ButtonsIndicators/buttonsIndicators.module').then(m => m.MateriaButoonIndicatorslModule)
      // },
      // {
      //   path: 'layout',
      //   loadChildren: () => import('./DemoPages/Material/Layout/layout.module').then(m => m.MaterialLayoutModule)
      // },
      // {
      //   path: 'form-controls',
      //   loadChildren: () => import('./DemoPages/Material/FormControls/formcontrols.module').then(m => m.MaterialFormControlModule)
      // },
      // {
      //   path: 'material',
      //   loadChildren: () => import('./DemoPages/Material/Material.module').then(m => m.MaterialModule)
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: () => import('./DemoPages/Widgets/Widgets.module').then(m => m.WidgetsModule)
      // },
      // {
      //   path: 'tables/bootstrap',
      //   loadChildren: () => import('./DemoPages/Tables/tables-main/tables-main.module').then(m => m.TablesMainModule)
      // },
      {
        path: '',
        redirectTo: 'pages/intake/dashboard',
        pathMatch: 'full'
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
